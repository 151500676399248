import React from 'react'
import ImagenBarraNavegacion from '../../../components/imagen-barra-navegacion/ImagenBarraNavegacion'
import { useTranslation } from 'react-i18next';

const NavegacionInnovacion = ({titulo,informacion}) => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
        <ImagenBarraNavegacion
        h1={titulo}
        p={informacion}
        nav_item1={t('seccionTecnologias.Tecnologias')}
        nav_item2={t('seccionTendencias.Tendencias')}
        nav_item3={t('seccionDesarrolloTecnologico.Desarrollo Tecnológico')}
      />
    </>
  )
}

export default NavegacionInnovacion