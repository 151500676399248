import React from "react";

import Faq from "../components/secciones/faq/Faq";

import Carrusel from "../components/carrusel/Carrusel";
import SeccionUniversidad from "../components/secciones/universidades/SeccionUniversidad";
import Seccion1 from "../components/secciones/seccion1/Seccion1";
import Seccion2 from "../components/secciones/seccion2/Seccion2";
import SeccionBlog from "../components/secciones/SeccionBlog/SeccionBlog";
import { useTranslation } from "react-i18next";
import Seccion9 from "../components/secciones/seccion9/seccion9";


const Home = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <Carrusel />
      <Seccion1
        p1={t(
          `home_seccion1.Fundación DEDICA el catalizador del talento joven`
        )}
        h2={"DEDICA:"}
        p2={t(
          `home_seccion1.Fundación DEDICA es un organismo zacatecano que nació con el propósito de impulsar el talento jóven, la innovación colaborativa, desarrollo digital y el conocimiento abierto.`
        )}
        p3={t(
          `home_seccion1.Con el objetivo de traer al estado innovación colaborativa e incentivar la tecnología digital, nace Fundación para el Desarrollo Digital y el Conocimiento Abierto (DEDICA), encabezada por Manuel Haro. Su principal finalidad es impulsar la comunidad de lOpen Source Innovation Lab a nivel nacional, para lo cual, la fundación podrá liderar estrategias de conjunto con otras organizaciones.`
        )}
        p4={""}
        btn={t(`home_seccion1.Ponerse en Contacto`)}
        img={require("../assets/img/mar2.jpg")}
      />

      <Seccion2
        h5={t(`home_seccion2.Antecedentes de Fundación DEDICA`)}
        p1={t(
          `home_seccion2.“Debido a toda esta actividad, el modelo comenzó a llamar la atención de grandes corporativos y fundaciones globales, asociaciones internacionales y eventos destacados”.`
        )}
        p2={t(
          `home_seccion2.Sus integrantes expresan que, desde la década de 1990, en el país se conformó una comunidad de académicos, empresarios y personas técnicamente dedicadas al desarrollo tecnológico, impulsando la adopción del software libre para el desarrollo de proyectos. Esto mismo, derivó en el propósito de lograr la adopción de tecnologías abiertas en diversos ámbitos.`
        )}
        p3={t(
          `home_seccion2.En 2011, se realizó el Primer Congreso Internacional de Software Libre en Zacatecas, en el cual se vivió la integración de comunidades académicas e importantes instituciones. De esta manera, se inició la articulación de esfuerzos para impulsar la cultura del uso de tecnologías abiertas en todos los sectores productivos del estado. Uno de estos esfuerzos se ve reflejado en la creación de un laboratorio dedicado al desarrollo de proyectos para resolver problemáticas de cualquier instancia tanto pública como privada. También destaca el trabajo legislativo para decretar la Ley Estatal de Software Libre en Zacatecas en el 2013.`
        )}
        img={require("../assets/img/Zacatecas3.jpg")}
      />

      <Seccion1
        h2={t(`home_seccion3.¿Cuál es su principal objetivo?`)}
        p2={t(
          `home_seccion3.Es importante reconocer el acompañamiento durante casí una década de grandes corporativos y organizaciones como Intel, Continental, Google Open Source, Gnome Foundation, Linux Foundation y muchos más.`
        )}
        p3={t(
          `home_seccion3.El objetivo es potenciar el acompañamiento y la visión global de los aliados ya mencionados en torno al ya consolidado Modelo de Innovación Colaborativa y Tecnologías Abiertas, desde hace algunos años ya se fraguaba la idea de crear una instancia formal que liderará esta comunidad y que adicionalmente fortaleciera la cultura de trabajo colaborativo y adopción de tecnologías de Software Libre y Open Source como pilar de estrategias de Innovación Tecnológica y Transformación Digital.`
        )}
        btn={t(`home_seccion3.Ponerse en Contacto`)}
        img={require("../assets/img/academy.jpeg")}
      />

      <SeccionUniversidad />

      <SeccionBlog />

      <Faq />

      {/* <Seccion9
        h2={t(`home_seccionDonacion.Apoyo Gnome`)}
        img={require("../assets/img/gnome.png")}
        url="https://www.gnome.org/donate/"
        p1={t(`home_seccionDonacion.Da click en el icono de Gnome si te gustaría donar`)}
      /> */}
    </div>
  );
};

export default Home;
