import React from "react";

import { Route, Routes } from "react-router-dom";
import Contacto from "../pages/Navegacion Principal/Contacto/Contacto";
import Layout from "../components/layouts/Layout";

import Home from "../pages/Home";


import Aliados from "../pages/Navegacion Principal/Aliados/Aliados";
import ComunidadesAcadémicasCientíficasInternacionales from "../pages/Navegacion Principal/Aliados/ComunidadesAcadémicasCientíficasInternacionales";
import EmpresasCompañíasGlobales from "../pages/Navegacion Principal/Aliados/EmpresasCompañíasGlobales";
import FundacionesOrganizacionesNoGubernamentales from "../pages/Navegacion Principal/Aliados/FundacionesOrganizacionesNoGubernamentales";
import LíderesReconocidosEspecializados from "../pages/Navegacion Principal/Aliados/LíderesReconocidosEspecializados";
import Beneficiarios from "../pages/Navegacion Principal/Beneficiarios/Beneficiarios";
import Gobierno from "../pages/Navegacion Principal/Beneficiarios/Gobierno";
import IndustriaBeneficiarios from "../pages/Navegacion Principal/Beneficiarios/IndustriaBeneficiarios";
import DessarrolloProyectos from "../pages/Navegacion Principal/Estrategias/DessarrolloProyectos";
import Estrategias from "../pages/Navegacion Principal/Estrategias/Estrategias";
import Eventos from "../pages/Navegacion Principal/Estrategias/Eventos/Eventos";
import OSIL from "../pages/Navegacion Principal/Estrategias/OSIL";
import DesarrolloTecnologico from "../pages/Navegacion Principal/Innovación/DesarrolloTecnologico";
import Innovación from "../pages/Navegacion Principal/Innovación/Innovación";
import Tecnologias from "../pages/Navegacion Principal/Innovación/Tecnologias";
import Tendencias from "../pages/Navegacion Principal/Innovación/Tendencias";
import Estructura from "../pages/Navegacion Principal/Nosotros/Estructura";
import Liderazgo from "../pages/Navegacion Principal/Nosotros/Liderazgo";
import Miembros from "../pages/Navegacion Principal/Nosotros/miembros/Miembros";
import Nosotros from "../pages/Navegacion Principal/Nosotros/Nosotros";
import Proposito from "../pages/Navegacion Principal/Nosotros/Proposito";
import Cuenta from "../pages/Navegacion Secundaria/Cuenta/Cuenta";
import Login from "../pages/Navegacion Secundaria/Login/Login";
import Usuarios from "../pages/Navegacion Secundaria/Usuarios/Usuarios";
import PosicionamientoTalento from '../pages/Navegacion Principal/Estrategias/PosicionamientoTalento';

import { routes } from "./routes";
import LOTS from "../pages/Navegacion Principal/Estrategias/Eventos/LOTS/LOTS";
import Contactos from "../pages/Navegacion Secundaria/Contactos/Contactos";
import ContactoDetalle from "../pages/Navegacion Secundaria/Contactos/components/ContactoDetalle/ContactoDetalle";
import CrearPublicacion from "../pages/Navegacion Secundaria/Blog/Publicaciones/CrearPublicacion/CrearPublicacion";
import EditarPublicacion from "../pages/Navegacion Secundaria/Blog/Publicaciones/EditarPublicacion/EditarPublicacion";
import Publicacion from "../pages/Navegacion Secundaria/Blog/Publicaciones/Publicacion/Publicacion";
import Blog from "../pages/Navegacion Secundaria/Blog/Blog/Blog";
import CrearEvento from "../pages/Navegacion Secundaria/Eventos/CrearEvento/CrearEvento";
import EditarEvento from "../pages/Navegacion Secundaria/Eventos/EditarEvento/EditarEvento";

const AppRouter = () => {
  return (
    <>
      <Layout>
        <Routes>
          
          <Route path={routes.home} element={<Home />}></Route>

          <Route path={routes.nosotros} element={<Nosotros />}></Route>
          <Route path={routes.proposito} element={<Proposito />}></Route>
          <Route path={routes.liderazgo} element={<Liderazgo />}></Route>
          <Route path={routes.miembros} element={<Miembros />}></Route>
          <Route path={routes.estructura} element={<Estructura />}></Route>

          <Route path={routes.estrategias} element={<Estrategias />}></Route>
          <Route path={routes.osil} element={<OSIL />}></Route>
          <Route path={routes.eventos} element={<Eventos />}></Route>
          <Route path={routes.lots} element={<LOTS/>}></Route>
          <Route
            path={routes.desarrollo_de_Proyectos}
            element={<DessarrolloProyectos />}
          ></Route>
          <Route
            path={routes.posicionamiento_de_Talento}
            element={<PosicionamientoTalento />}
          ></Route> 
         

          <Route path={routes.aliados} element={<Aliados />}></Route>
          <Route path={routes.Comunidades_Académicas_y_Científicas_Internacionales} element={<ComunidadesAcadémicasCientíficasInternacionales/>}></Route>
          <Route path={routes.Empresas_y_Compañías_globales} element={<EmpresasCompañíasGlobales />}></Route>
          <Route path={routes.Fundaciones_y_Organizaciones_No_Gubernamentales} element={<FundacionesOrganizacionesNoGubernamentales />}></Route>
          <Route path={routes.Líderes_reconocidos_y_especializados} element={<LíderesReconocidosEspecializados />}></Route>

          <Route path={routes.innovacion} element={<Innovación />}></Route>
          <Route path={routes.tecnologias} element={<Tecnologias />}></Route>
          <Route path={routes.tendencias} element={<Tendencias />}></Route>
          <Route
            path={routes.desarrollo_Tecnologico}
            element={<DesarrolloTecnologico />}
          ></Route>

          <Route path={routes.beneficiarios} element={<Beneficiarios />}></Route>
          <Route
            path={routes.industriaBeneficiarios}
            element={<IndustriaBeneficiarios />}
          ></Route>
          <Route path={routes.gobierno} element={<Gobierno />}></Route>
          

          <Route path={routes.contacto} element={<Contacto />}></Route>
          <Route path={routes.contactoDetalle} element={<ContactoDetalle/>}></Route>

          <Route path={routes.blog} element={<Blog />} />
          
          <Route path={routes.crearPublicacion}  element={<CrearPublicacion/>}/>
          <Route path={routes.editarPublicacion} element={<EditarPublicacion/>}/>
          <Route path={routes.publicacion}  element={<Publicacion/>}/>

          <Route path={routes.crearEvento}  element={<CrearEvento/>}/>
          <Route path={routes.editarEvento} element={<EditarEvento/>}/>

          <Route path={routes.login} element={<Login/>} />
          

          <Route path={routes.admin.users} element={<Usuarios/>}/>
          <Route path={routes.contactos} element={<Contactos/>}/>
          <Route path={routes.cuenta} element={<Cuenta/>}/>
          

          <Route path="*" element={<Home />} />
        </Routes>
      </Layout>
    </>
  );
};

export default AppRouter;
