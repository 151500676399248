import React from 'react'
import NavegacionBeneficiarios from './NavegacionBeneficiarios'
import { useTranslation } from 'react-i18next';

const Beneficiarios = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
        <NavegacionBeneficiarios titulo={t('seccionBeneficiarios.Beneficiarios')} informacion={t("seccionBeneficiarios.Seccion de Beneficiarios")}/>

    </>
  )
}

export default Beneficiarios