import React from "react";

import "./Seccion4.css";

const Seccion4 = ({ p1, p2, p3,p4,h2, img }) => {
  return (
    <>
      <div className="Seccion4">
        <div className="Seccion4__seccion">
          <div className="Seccion4__imagen">
            <img src={img} alt="Imagen"/>
          </div>
          <div className="Seccion4__informacion">
            <p>{p1}</p>
            <h2>{h2}</h2>
            <p>{p2}</p>
            <p>{p3}</p>
            <p>{p4}</p>

          </div>
        </div>
      </div>
    </>
  );
};

export default Seccion4;
