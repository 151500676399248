import { Modal, Alert, Button } from "react-bootstrap";
import { useNavigate } from "react-router";

import { routes } from "../../../../routers/routes";
import { UserContext } from "../../../../Context/UserContextProvider";
import { useContext } from "react";

const EliminarModal = ({ isOpen, close }) => {
  const navigate = useNavigate();
  const { setUserInfo, userInfo } = useContext(UserContext);

  const logout = async () => {
    const response= await fetch("https://backend-dedica.onrender.com/api/auth/logout", {
      method: "POST",
      credentials: 'include' 
    });
    setUserInfo(null);
    console.log(await response.json());
    navigate(routes.home)
  };

  const eliminarUsuario = async () => {
    const response = await fetch(`https://backend-dedica.onrender.com/user/deleteAccount`, {
      method: "DELETE",
      credentials: "include",
    });

    if (response.status==204) {
      logout();
      close();
      
    }
  };

  

  return (
    <>
      <Modal show={isOpen} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            ¿Estas seguro que deseas eliminar permanentemente esta cuenta?
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={eliminarUsuario}>
            Eliminar mi cuenta
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EliminarModal;
