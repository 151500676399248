import React from 'react'
import PieDePagina from '../../components/Pie-de-Pagina/PieDePagina'
import MiniBarraNavegacion from '../mini-barra-navegacion/MiniBarraNavegacion'
import NavBarPrincipal from '../principal-barra-navegacion/NavBarPrincipal/NavBarPrincipal'
import './Layout.css'

const Layout = ({children}) => {
  return (
    <>
        
        <MiniBarraNavegacion/>
        <NavBarPrincipal/>
        <div className='Layout__contenedor'>
        {children}
        </div>
        <PieDePagina/>
    </>
  )
}

export default Layout