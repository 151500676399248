import React from "react";
import NavegacionNosotros from "../NavegacionNosotros";
import TarjetaDeMiembro from "../../../../components/miembros/TarjetaDeMiembro";
import { informacionMiembros } from "./informacionMiembros"
import "../../../../components/miembros/EstilosGlobalesMiembros.css";
import TituloPrincipal from "../../../../components/secciones/tituloPrincipal/TituloPrincipal";
import { useTranslation } from "react-i18next";

const Miembros = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
    <NavegacionNosotros titulo={t('seccionMiembros.Miembros')} informacion={t('seccionMiembros.Seccion de Miembros')}/>      
      <TituloPrincipal 
      encabezado={t('seccionMiembros.Conoce a los miembros de la fundación')}
      texto={t('seccionMiembros.texto')}/>
      <TarjetaDeMiembro arrayParam={informacionMiembros} />
    </>
  );
};


export default Miembros;