import React from "react";
import NavegacionNosotros from "./NavegacionNosotros";
import Seccion8 from "../../../components/secciones/seccion8/Seccion8";
import { useTranslation } from "react-i18next";

const Proposito = () => {

  
  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionNosotros
        titulo={t('seccionProposito.Proposito')}
        informacion={t('seccionProposito.Seccion de Proposito')}
      />
      <Seccion8
        h2={t('seccionProposito.Proposito')+":"}
        p1={'Open Source'}
        p2={t('seccionProposito.Es importante reconocer el acompañamiento durante casi una década  de grandes corporativos y organizaciones como Intel, Continental, Google Open Source, Gnome Foundation, Linux Foundation y muchos más. El objetivo es potenciar el acompañamiento y la visión global de los aliados ya mencionados en torno al ya  consolidado Modelo de Innovación Colaborativa y Tecnologías Abiertas, desde hace algunos años ya se fraguaba la idea de crear una instancia formal que liderará esta comunidad y que adicionalmente fortaleciera la cultura de trabajo colaborativo y adopción de tecnologías de Software Libre y Open Source como pilar de estrategias de Innovación Tecnológica y Transformación Digital.')}
        p3={t('seccionProposito.La estructura y la misión de la Fundación DEDICA se enfoca en consolidar un ecosistema de innovación tecnológica y transformación digital de manera multisectorial, de tal forma que instancias de cualquier sector pueden encontrar en la Fundación DEDICA y el Open Source Innovation Lab un gran aliado para resolver problemáticas así como crear ideas innovadoras a través de proyectos de tecnológicos.')}
        img1={require('../../../assets/img/OpenSource.jpg')}
        img2={require('../../../assets/img/jovenes.jpg')}
        p4={t('seccionProposito.Las instituciones de educación medía superior y superior son el más importante colaborador de la Fundación que ofrece a todas estas comunidades académicas una instancia colaborativa donde sus estudiantes y recién egresados fortalezcan sus habilidades a través de experiencia colaborando en proyectos reales, actualicen sus conocimientos a través de programas de capacitación novedosos y ofrecer a los jóvenes talentosos oportunidades reales de posicionamiento profesional.')}
      />
    </>
  );
};

export default Proposito;
