import React from "react";

import NavegacionEstrategias from "./NavegacionEstrategias";
import Seccion7 from "../../../components/secciones/seccion7/Seccion7";
import { useTranslation } from "react-i18next";

const PosicionamientoTalento = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionEstrategias
        titulo={t('seccionPosicionamientoTalento.Posicionamiento de Talento')}
        informacion={t('seccionPosicionamientoTalento.Seccion de Posicionamiento de Talento')}
      />

      <Seccion7
        h2={t('seccionPosicionamientoTalento.Posicionamiento de Talento')}
        p1={"Open Source Innovation Lab"}
        p2={t('seccionPosicionamientoTalento.texto1')}
        p3={t('seccionPosicionamientoTalento.texto2')}
        img={require('../../../assets/img/portada_1.jpg')}
      />
    </>
  );
};

export default PosicionamientoTalento;
