import React from 'react'
import NavegacionBeneficiarios from './NavegacionBeneficiarios'
import { useTranslation } from 'react-i18next';

const IndustriaBeneficiarios = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
        <NavegacionBeneficiarios titulo={t('seccionIndustria.Industria')} informacion={t("seccionIndustria.Seccion de Industria")}/>
  
    </>
  )
}

export default IndustriaBeneficiarios