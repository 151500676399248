import React, { useEffect, useState } from "react";

import { informacion } from "./informacionCarrusel";

import "./Carrusel.css";
import Carousel from "react-bootstrap/Carousel";
import { routes } from "../../routers/routes";
import { useTranslation } from "react-i18next";

const Carrusel = () => {

  const [t,i18n]=useTranslation("global");

  const [posts, setPosts] = useState([]);

  const traerPosts = async () => {
    const response = await fetch(
      "https://backend-dedica.onrender.com/publicacion/getPublicaciones",
      {
        method: "GET",
        
      }
    );

    const posts = await response.json();
    setPosts(posts);
  };

  useEffect(() => {
    traerPosts();
  }, []);

  return (
    <Carousel className="carrusel" fade>
      {informacion.map((item) => {
        return (
          <Carousel.Item key={item.id}>
            <div className="carrusel">
              <img
                className={item.Cname}
                src={require(`../../assets/img/${item.img}`)}
                alt="/"
              />
            </div>
            <Carousel.Caption className="carrusel__informacion">
              <div className="carrusel__contenedor">
                <h2> {t(`carrusel.${item.title}`)}</h2>
                <p>{t(`carrusel.${item.text}`)}</p>
                
                  <div>
                  <a href={item.link}  rel="noreferrer">
                  {t(`carrusel.Aprender Más`)}
                  </a>
                  </div>
                
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
      {posts.map((item)=>{
        return (
          <Carousel.Item key={item.titulo}>
            <div className="carrusel">
              <img
                className='carrusel__imagenes'
                src={item.imagen}
                alt="/"
              />
            </div>
            <Carousel.Caption className="carrusel__informacion">
              <div className="carrusel__contenedor">
                <h2>{item.titulo}</h2>
                {/* <p>{item.resumen}</p> */}
                
                  <div>
                  <a href={`/publicacion/${item._id}`}  rel="noreferrer">
                  {t(`carrusel.Aprender Más`)}
                  </a>
                  </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )
      })}
    </Carousel>
  );
};

export default Carrusel;
