import React, { useContext, useEffect, useState } from "react";

import "./Cuenta.css";

import icono from "../../../assets/img/iconos/male_avatar.svg";

import EliminarCuentaModal from "./components/EliminarCuentaModal";
import { Button, Card } from "react-bootstrap";
import CambiarContraseñaCuentaModal from "./components/CambiarContraseñaCuentaModal";
import useModal from "../../../hooks/useModal";
import EditarCuentaModal from "./components/EditarCuentaModal";
import { UserContext } from "../../../Context/UserContextProvider";

const Cuenta = () => {


  const [isOpenEliminarCuentaModal, openEliminarCuentaModal, closeEliminarCuentaModal] =
    useModal();
  const [
    isOpenCambiarContraseñaCuentaModal,
    openCambiarContraseñaCuentaModal,
    closeCambiarContraseñaCuentaModal,
  ] = useModal();
  const [
    isOpenEditarCuentaModal,
    openEditarCuentaModal,
    closeEditarCuentaModal,
  ] = useModal();


  const {userInfo}= useContext(UserContext);

  

 
  
  useEffect(() => {
    
  }, [userInfo]);


  return (
    <>
      <div className="Cuenta">
        <img src={icono} alt="Imagen de Usuario" />
        <Card className="Cuenta__informacion">
          <p>
            <b>Nombre:</b> {userInfo?.username}
          </p>
          <p>
            <b>Email:</b> {userInfo?.email}
          </p>
          <p>
            <b>Rol:</b> {userInfo?.role}
          </p>

          <Button
            className="Cuenta__boton__editarCuenta"
            onClick={openEditarCuentaModal}
          >
            Editar Cuenta
          </Button>
          <Button
            className="Cuenta__boton__cambiarContraseña"
            onClick={openCambiarContraseñaCuentaModal}
          >
            Cambiar contraseña
          </Button>
          <Button
            className="Cuenta__boton__eliminarContraseña"
            onClick={openEliminarCuentaModal}
          >
            Eliminar Cuenta
          </Button>
        </Card>
      </div>

      <EliminarCuentaModal isOpen={isOpenEliminarCuentaModal} close={closeEliminarCuentaModal} />

      <CambiarContraseñaCuentaModal
        isOpen={isOpenCambiarContraseñaCuentaModal}
        close={closeCambiarContraseñaCuentaModal}
      />

      <EditarCuentaModal
      
      
        isOpen={isOpenEditarCuentaModal}
        close={closeEditarCuentaModal}
      />
    </>
  );
};

export default Cuenta;
