import { Modal, Form, Button, Alert } from "react-bootstrap";

import { useForm } from "react-hook-form";
import ValidarCambiarContraseñaUsuario from "../../../../validaciones/Usuarios/ValidarCambiarContraseñaUsuario";

const CambiarContraseñaCuentaModal = ({ isOpen, close }) => {
  

  const { register, handleSubmit,formState } = useForm({resolver:ValidarCambiarContraseñaUsuario});
  const {errors}= formState;

  //handleSubmit Sirve tambien para construir la funcion que envia el los datos que se ingresaron en el form
  //register es un objeto de atributos para el input
  //formData son los datos enviados en el formulario

  

  const editarContraseña =async (formData) => {
    


    const response = await fetch("https://backend-dedica.onrender.com/user/updatePassword", {
      method: "PUT",
      body: JSON.stringify(formData),
      headers: { "Content-Type": "application/json" },
      credentials:'include'
    });


    if (response.ok) {
      close();
    }

  };

  
  

  return (
    <>
      <Modal show={isOpen} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Cambiar contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(editarContraseña)}>
            <Form.Group>
              <Form.Label>Nueva Contraseña</Form.Label>
              <Form.Control
                placeholder="Escribe una nueva contraseña"
                {...register("password")}
                type='password'
              />
              {errors?.password && (
                <Form.Text>
                <Alert variant="danger">
                    {errors.password.message}
                    </Alert> 
              </Form.Text>
              )}
            </Form.Group>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit(editarContraseña)}>
            Actualizar Contraseña
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CambiarContraseñaCuentaModal;
