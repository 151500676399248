import React from "react";

import "./Seccion8.css";

const Seccion8 = ({ h2, p1, p2, p3, img1, p4, img2 }) => {
  return (
    <>
      <div className="seccion8">
        <div className="seccion8__seccion">
          <div className="seccion8__textoImagen">
            <div className="seccion8__textoImagen__informacion">
              <p>{p1}</p>
              <h2>{h2}</h2>
              <p>{p2}</p>
              <p>{p3}</p>
            </div>
            <div className="seccion8__textoImagen__imagen">
              <img src={img1} alt="Imagen" />
            </div>
          </div>
          <div className="seccion8__imagenTexto">
            <div className="seccion8__imagenTexto__imagen">
              <img src={img2} alt="Imagen" />
            </div>
            <div className="seccion8__imagenTexto__informacion">
              <p>{p4}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Seccion8;
