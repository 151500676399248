import React from "react";

import "./Seccion6.css";

const Seccion6 = ({ h2, p1, p2, img1, p3, img2 }) => {
  return (
    <>
      <div className="seccion6">
        <div className="seccion6__seccion">
          <div className="seccion6__textoImagen">
            <div className="seccion6__textoImagen__informacion">
              <h2>{h2}</h2>
              <p>{p1}</p>
              <p>{p2}</p>
            </div>
            <div className="seccion6__textoImagen__imagen">
              <img src={img1} alt='Imagen' />
            </div>
          </div>
          <div className="seccion6__imagenTexto">
            <div className="seccion6__imagenTexto__imagen">
              <img src={img2} alt='Imagen' />
            </div>
            <div className="seccion6__imagenTexto__informacion">
              <p>{p3}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Seccion6;
