export const agenda1=[
    {
        horas:'8:00-9:00',
        localizacion:'',
        contenido:'Attenders Register',
        orador:'',
        origen:''
    },
    {
        horas:'9:00-9:30',
        localizacion:'Auditorio',
        contenido:'Inaguración formal de LOTS y firmas de Convenio',
        orador:'Organizacipon LOTS',
        origen:'LOTS'
    },
    {
        horas:'9:30-10:00',
        localizacion:'Auditorio',
        contenido:'Megatendencias Tecnológicas Globales',
        orador:'Ramón Morales',
        origen:'Fundación DEDICA'
    },
    {
        horas:'10:00-10:30',
        localizacion:'Auditorio',
        contenido:'Adopción de tecnologias Web3 en América Latina',
        orador:'Claudio Cossio',
        origen:'ICP Latam Hub'
    },
    {
        horas:'10:30-11:00',
        localizacion:'Auditorio',
        contenido:'Desarrollo de talento local desde afuera',
        orador:'Julio González',
        origen:'Encora Co.'
    },
    {
        horas:'11:00-11:30',
        localizacion:'',
        contenido:'Break',
        orador:'',
        origen:''
    },
    {
        horas:'11:30-12:00',
        localizacion:'Auditorio',
        contenido:'Agil Startup Inception',
        orador:'Ivan Chenoweth',
        origen:'UT Hermosillo'
    },
    {
        horas:'11:30-12:00',
        localizacion:'Audiovisual',
        contenido:'El papel del Open Source en la Innovación Tecnológica',
        orador:'Héctor Benitez',
        origen:'Encora'
    },
    {
        horas:'12:00-12:30',
        localizacion:'Auditorio',
        contenido:'Software Libre para la seguridad en el desarrollo de Software',
        orador:'Felipe Cabada',
        origen:'ITSON Ciudad Obregon'
    },
    {
        horas:'12:00-12:30',
        localizacion:'Audiovisual',
        contenido:'',
        orador:'Cristina Lostanau',
        origen:'La Zona 3'
    },
    {
        horas:'12:30-13:00',
        localizacion:'Auditorio',
        contenido:'Innovar en el desarrollo de soluciones de control escolar, y hacerlo de forma sostenible a las UTs',
        orador:'M.E. José Alonso López Romo',
        origen:'UT Hermosillo'
    },
    {
        horas:'12:30-13:00',
        localizacion:'Audiovisual',
        contenido:'Libre Office, Que es? Como puedo aportar?',
        orador:'Adlair Cerecedo',
        origen:'LibreOffice'
    },
    {
        horas:'13:00-13:30',
        localizacion:'Auditorio',
        contenido:'“Flutter y como el open-source cambio el mundo del desarrollo móvil”',
        orador:'Ing. Argel Franco Bejarano de la Cruz',
        origen:'Flutter & Dart GDE | Writer, Speaker and Founder @esFlutter'
    },
    {
        horas:'13:00-13:30',
        localizacion:'Audiovisual',
        contenido:'Specialized Talk',
        orador:'',
        origen:''
    },
    {
        horas:'13:30-14:00',
        localizacion:'Auditorio',
        contenido:'Tecnologías para Inferencias de Comportamientos y Actividades Humanas',
        orador:'Luis Castro',
        origen:'ITSON & InnovationLabs'
    },
    {
        horas:'13:30-14:00',
        localizacion:'Audiovisual',
        contenido:'Specialized Talk',
        orador:'',
        origen:''
    },
    {
        horas:'14:00-16:00',
        localizacion:'',
        contenido:'Break',
        orador:'',
        origen:''
    },
    {
        horas:'Taller Practico 16:00-19:00',
        localizacion:'OSIL UTH',
        contenido:'Chamilo, un modelo de Sistema de gestion del aprendizaje Open Source',
        orador:'Felipe Cabada',
        origen:'ITSON'
    },
    {
        horas:'Taller Practico 16:00-19:00',
        localizacion:'Laboratorio 1 UTH',
        contenido:'Docker, laravel y codespaces',
        orador:'Ivan Chenoweth',
        origen:'UT Hermosillo'
    },
    {
        horas:'Taller Practico 16:00-19:00',
        localizacion:'Laboratiorio 2 UTH',
        contenido:'Desarrolla tu primer contrato inteligente',
        orador:'Ayrton Klassen',
        origen:'OSIL / Nativo Crypto'
    },
    {
        horas:'19:00-21:00',
        localizacion:'',
        contenido:'LOTS 2023 Fiesta de Bienvenida',
        orador:'',
        origen:''
    },
]
