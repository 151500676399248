import React from 'react'
import NavegacionAliados from './NavegacionAliados'
import { useTranslation } from 'react-i18next';

const Aliados = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionAliados titulo={t('navbar.Aliados')} informacion={t('navbar.Seccion de Aliados')}/>
  
    </>
  )
}

export default Aliados