import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

import './FormOradores.css'

const FormOradores = ({ setOradores,oradores,oradoresArray }) => {
  const [nombre, setNombre] = useState("");
  const [ocupacion, setOcupacion] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [imagen, setImagen] = useState("");
  const [linkedin, setLinkedin] = useState("");

  const guardarOrador = () => {
    const orador = {
      nombre,
      ocupacion,
      descripcion,
      imagen,
      linkedin

    };

    oradoresArray.push(orador);
    console.log(oradoresArray)
  };

  //Controladores de check
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Form className="FormularioEvento__formularioOrador" onSubmit={guardarOrador}>
      <Form.Label>Orador</Form.Label>
      <Form.Group>
        <Form.Label>Nombre de orador </Form.Label>
        <Form.Control
          type="text"
          nombre={nombre}
          onChange={(e) => setNombre(e.currentTarget.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Ocupación de orador </Form.Label>
        <Form.Control
          type="text"
          ocupacion={ocupacion}
          onChange={(e) => setOcupacion(e.currentTarget.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>
          Descripción breve de orador (máximo 60 palabras){" "}
        </Form.Label>
        <Form.Control
          type="text"
          descripcion={descripcion}
          onChange={(e) => setDescripcion(e.currentTarget.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Imagen</Form.Label>
        <Form.Control
          type="file"
          imagen={imagen}
          onChange={(e) => setImagen(e.currentTarget.files[0])}
        />
      </Form.Group>
      <Form.Check
        type="switch"
        id="custom-switch"
        label="Agregar LinkedIn"
        checked={isChecked}
        onChange={handleOnChange}
      />

      {isChecked === true && (
        <Form.Group>
          <Form.Label>Enlace de LinkedIn </Form.Label>
          <Form.Control type="text" placeholder="Enlace" linkedin={linkedin}
          onChange={(e) => setLinkedin(e.currentTarget.value)}/>
        </Form.Group>
      )}

      <Form.Group>
        <Button variant="secondary" onClick={guardarOrador}>
          Guardar Orador
        </Button>
      </Form.Group>
    </Form>
  );
};

export default FormOradores;
