import React from 'react'
import NavegacionInnovacion from './NavegacionInnovacion'
import { useTranslation } from 'react-i18next';

const Tecnologias = () => {

  const [t,i18n]=useTranslation("global");

  return (
   <>
     <NavegacionInnovacion titulo={t('seccionTecnologias.Tecnologias')} informacion={t("seccionTecnologias.Seccion de Tecnologias")}/>

   </>
    
  )
}

export default Tecnologias