import React from 'react'
import NavegacionEstrategias from './NavegacionEstrategias'
import { useTranslation } from 'react-i18next';

const OSIL = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
        <NavegacionEstrategias titulo={"OSIL"} informacion={t(`seccionOSIL.Seccion de OSIL`)}/>
    </>
  )
}

export default OSIL