import React, { useEffect, useState } from "react";

import "./Contactos.css";
import SeleccionarCategoria from "./components/SeleccionarCategoria/SeleccionarCategoria";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { routes } from "../../../routers/routes";

const Contactos = () => {
  const [solicitudes, setSolicitudes] = useState([]);
  const [solicitudes2, setSolicitudes2] = useState([]);

  const [compañia, setCompañia] = useState([]);
  const [pais, setPais] = useState([]);
  const [consulta, setConsulta] = useState([]);

  const mostrarAlertaEliminar = () => {
    swal({
      title: "Eliminación exitosa de Solicitud de Contacto!",
      icon: "success",
      timer: 3000,
    });
  };

  const eliminarSolicitud= async (id)=>{
    const response= await fetch(`https://backend-dedica.onrender.com/contacto/deleteSolicitudes/${id}`,
    {
      method: "DELETE",
      credentials: "include",
    })

    if (response.ok) {
        mostrarAlertaEliminar();
        traerSolicitudes();
      }
  }

  const traerSolicitudes = async () => {
    const response = await fetch(
      "https://backend-dedica.onrender.com/contacto/getSolicitudes",
      {
        method: "GET",
        credentials: "include",
      }
    );

    var solicitudesDoc = await response.json();
    setSolicitudes(solicitudesDoc);
    setSolicitudes2(solicitudesDoc);
  };

  useEffect(() => {
    traerSolicitudes();
  }, []);

  useEffect(() => {
    setCompañia([
      ,...new Set(solicitudes.map((solicitud) => solicitud.compañia)),
    ]);
    setPais([...new Set(solicitudes.map((solicitud) => solicitud.pais))]);
    setConsulta([
        ...new Set(solicitudes.map((solicitud) => solicitud.consulta)),
    ]);
  }, [solicitudes]);

  const filtrarCategoria = (categoria, dato) => {
    if (categoria === "Todos") {
      setSolicitudes2(solicitudes);
      return;
    }

    if (categoria === "compañia") {
      const filteredData = solicitudes.filter(
        (solicitud) => solicitud.compañia === dato
      );
      setSolicitudes2(filteredData);
      return;
    } else if (categoria === "pais") {
      const filteredData = solicitudes.filter(
        (solicitud) => solicitud.pais === dato
      );
      setSolicitudes2(filteredData);
      return;
    } else if (categoria === "consulta") {
      const filteredData = solicitudes.filter(
        (solicitud) => solicitud.consulta === dato
      );
      setSolicitudes2(filteredData);
      return;
    }
  };

  return (
    <>
      <section className="Contactos">
        <div className="Contactos__contenedor">
          <div className="Contactos__titulo">
            <h1>Solicitudes de contactos</h1>
            <div className="Contactos__selectCategorias">
              <button onClick={() => filtrarCategoria("Todos")}>Todos</button>
              <SeleccionarCategoria
                categorias={compañia}
                filtrarCategoria={filtrarCategoria}
                categoria="compañia"
              />
              <SeleccionarCategoria
                categorias={pais}
                filtrarCategoria={filtrarCategoria}
                categoria="pais"
              />
              <SeleccionarCategoria
                categorias={consulta}
                filtrarCategoria={filtrarCategoria}
                categoria="consulta"
              />
            </div>
          </div>
          <table className="Contactos__tabla">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Compañia</th>
                <th>Cargo</th>
                <th>Pais</th>
                <th>Email</th>
                <th>Consulta</th>
              </tr>
            </thead>
            <tbody>
              {solicitudes2.map((solicitud) => {
                return (
                  <tr className="Contactos__tabla__renglon" key={solicitud._id}>
                    <td>{solicitud.nombre}</td>
                    <td>{solicitud.apellido}</td>
                    <td>{solicitud.compañia}</td>
                    <td>{solicitud.cargo}</td>
                    <td>{solicitud.pais}</td>
                    <td>{solicitud.email}</td>
                    <td>{solicitud.consulta}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={()=>eliminarSolicitud(solicitud._id)}
                        id="icono__Basura"
                      />{" "}
                      <Link to={`/Contactos/${solicitud._id}`}>
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        
                      />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default Contactos;
