import { formatISO9075 } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

import "./Post.css";

const Post = ({
  _id,
  titulo,
  resumen,
  contenido,
  imagen,
  createdAt,
  autor,
}) => {
  return (
    <>
      <div className="Post">
        <div className="Post__seccion">
          <div className="Post__publicacion">
            <div className="Post__imagen">
              <Link to={`/publicacion/${_id}`}>
                <img src={imagen} alt="" />
              </Link>
            </div>
            <div className="Post__textos">
              <Link to={`/publicacion/${_id}`}>
                <h2>{titulo}</h2>
              </Link>

              <p className="Post__info">
                {/* <a href="/" className="Post__autor">
                  {autor.username}
                </a> */}
                <time>{formatISO9075(new Date(createdAt),{ representation: 'date' })}</time>
              </p>
              <p className="Post__resumen">{resumen}</p>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default Post;
