import React from "react";
import Ubicacion from "../../../components/ubicación/Ubicacion";
import SeccionContacto from "../../../components/contacto/SeccionContacto";

const Contacto = () => {
  return (
    <>
      <Ubicacion />
      <SeccionContacto />
    </>
  );
};

export default Contacto;
