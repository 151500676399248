import React, { useEffect, useState } from "react";
import Post from "./components/Post";

import "./components/Post.css";

const Blog = () => {
  const [posts, setPosts] = useState([]);

  const traerPosts = async () => {
    const response = await fetch(
      "https://backend-dedica.onrender.com/publicacion/getPublicaciones",
      {
        method: "GET",
        
      }
    );

    const posts = await response.json();
    setPosts(posts);
  };

  useEffect(() => {
    traerPosts();
  }, []);

  return (
    <>
      {posts.length > 0 &&
        posts.map((post) => {
          return(
            <>
            <Post key={post._id} {...post} />;
            </>
          )
        })}
    </>
  );
};

export default Blog;
