import React from "react";
import { laboratorios } from "./laboratorios";

import './Ubicacion.css';
import { useTranslation } from "react-i18next";

const Ubicacion = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <section className="Ubicacion">
        <div className="Ubicacion__seccion">
          <div className="Ubicacion__domicilio">
            <div className="Ubicacion__introduccion">
              <h2>{t(`seccionContacto.Localización de oficionas`)}</h2>
              <p>
              {t(`seccionContacto.Por favor contáctenos con cualquier consulta o pregunta general que tenga.`)}
              </p>
            </div>
            <div className="Ubicacion__mapaDomicilio">
              <img src={require('../../assets/img/ubicacion/ubicacion.png')} alt="" />
              <div className="Ubicacion__mapaDomicilio__Domicilio">
                <h3>{t(`seccionContacto.Fundacion Dedica`)}</h3>
                <p>
                  Rubén Dario #618-B, Col. Marianita Zacatecas, Zac. CP 98060
                </p>
                <div className="Ubicacion__mapaDomicilio__link">
                <a target="_blank" href="https://www.google.com/maps/search/Rub%C3%A9n+Dario+%23618-B,+Col.+Marianita+Zacatecas,+Zac.+CP+98060/@22.7736812,-102.5675964,16.5z">{t(`seccionContacto.Ver mapa`)}</a>
              </div>
              </div>
              
            </div>
          </div>
          <div className="Ubicacion__sedes">
            <h4>{t(`seccionContacto.Tambien tenemos laboratorios en`)}</h4>
            <ul>
                {laboratorios.map(item=>{
                    return(
                        <li>{item}</li>
                    )
                })}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Ubicacion;
