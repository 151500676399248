import React from "react";
import "./TituloPrincipal.css";

const TituloPrincipal = ({ encabezado, texto }) => {
  return (
    <div className="TituloPrincipal">
      <div className="TituloPrincipal__contenedor">
        <h2>{encabezado}</h2>
        <div className="TituloPrincipal__lineaDivisora"></div>
        <p>{texto}</p>
      </div>
    </div>
  );
};

export default TituloPrincipal;
