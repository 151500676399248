import React, { useState } from "react";

import "./SeleccionarCategoria.css";

const SeleccionarCategoria = ({ categorias, filtrarCategoria, categoria }) => {
  const [value, setValue] = useState();

  return (
    <>
      <select
        className="SeleccionarCategoria__Select"
        name={categoria}
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        onClick={() => filtrarCategoria(categoria, value)}
      >
        <option hidden value="DEFAULT" disabled>
          {categoria}
        </option>
        {categorias.map((dato) => {
          return (
            <option value={dato} key={dato}>
              {dato}
            </option>
          );
        })}
      </select>
    </>
  );
};

export default SeleccionarCategoria;
