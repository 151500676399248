import React from 'react'
import NavegacionInnovacion from './NavegacionInnovacion'
import { useTranslation } from 'react-i18next';

const DesarrolloTecnologico = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
        <NavegacionInnovacion titulo={t('seccionDesarrolloTecnologico.Desarrollo Tecnológico')} informacion={t("seccionDesarrolloTecnologico.Seccion de Desarrollo Tecnológico")}/>
   
     
    </>
  )
}

export default DesarrolloTecnologico