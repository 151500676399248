import React from "react";
import Seccion3 from "../../../components/secciones/seccion3/Seccion3";
import NavegacionNosotros from "./NavegacionNosotros";
import { useTranslation } from "react-i18next";

const Estructura = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionNosotros titulo={t(`seccionEstructura.Estructura`)} informacion={t(`seccionEstructura.Seccion de Estructura`)} />
      <Seccion3
        h2={t(`seccionEstructura.Estructura`)} 
        p1={t(`seccionEstructura.texto1`)} 
        h3={"Open Source Innovation Lab ( OSIL )"}
        p2={t(`seccionEstructura.texto2`)} 
        img1={require('../../../assets/img/dedicalogo.png')}
        img2={require('../../../assets/img/opensourcelogo.png')}
      />
    
    </>
  );
};

export default Estructura;
