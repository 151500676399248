import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  email: yup
    .string("EL email debe ser un texto")
    .matches(
      /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/,
      "Ingresa un email valido"
    )
    .required("Debe de ingresar un email")
    .email("Debe ingresar un email valido"),
  password: yup
    .string("La contraseña debe ser un texto ")
    .matches(
      /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
      "La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula. NO puede tener otros símbolos."
    )
    .required("Debes ingresar una contraseña"),
});

export default yupResolver(schema);
