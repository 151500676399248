import React from "react";
import EventoInformacion from "./components/EventoInformacion/EventoInformacion";
import EventoOradores from "./components/EventoOradores/EventoOradores";
import TituloSecundario from "../secciones/tituloSecundario/TituloSecundario";
import EventoAgenda from "./components/EventoAgenda/EventoAgenda";

import "./LandingPageEvento.css";

const LandingPageEvento = ({
  p1,
  p2,
  p3,
  img,
  oradores,
  agenda1,
  dia1,
  agenda2,
  dia2,
  btn,
  url
}) => {
  return (
    <>
      <section className="LandingPageEvento">
        <EventoInformacion p1={p1} p2={p2} p3={p3} img={img} />
        <TituloSecundario encabezado={"Oradores"} />
        <EventoOradores oradores={oradores} />
        <EventoAgenda titulo={"Agenda"} agenda={agenda1} dia={dia1} />
        <EventoAgenda agenda={agenda2} dia={dia2} />
        <div className="LandingPageEvento__btn">
          <a
            href={url}
            target="_blank"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {btn}
          </a>
        </div>
      </section>
    </>
  );
};

export default LandingPageEvento;
