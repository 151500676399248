import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router";
import swal from "sweetalert";
import { routes } from "../../../../routers/routes";
import { lugares } from "../components/Lugares";
import { UserContext } from "../../../../Context/UserContextProvider";

const EditarEvento = () => {
  const [titulo, setTitulo] = useState("");
  const [modalidad, setModalidad] = useState("");
  const [lugar, setLugar] = useState("");
  const [fecha, setFecha] = useState("");
  const [hora, setHora] = useState("");
  const [resumen, setResumen] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [url, setUrl] = useState("");
  const [imagen, setImagen] = useState("");

  const { id } = useParams();

  const navigate = useNavigate();

  const {userInfo}= useContext(UserContext);

  const mostrarAlertaEditar = () => {
    swal({
      title: "Se actualizo de manera exitosa el Evento!",
      icon: "success",
      timer: 3000,
    });
  };

  const traerEvento = async () => {
    const response = await fetch(
      `https://backend-dedica.onrender.com/evento/getEvento/${id}`
    );

    const eventoInfo = await response.json();

    setTitulo(eventoInfo.titulo);
    setModalidad(eventoInfo.modalidad);
    setLugar(eventoInfo.lugar);
    setFecha(eventoInfo.fecha);
    setHora(eventoInfo.hora);
    setResumen(eventoInfo.resumen);
    setDescripcion(eventoInfo.descripcion);
    setUrl(eventoInfo.url);
  };

  useEffect(() => {
    traerEvento();
  }, []);

  const editarEvento = async (ev) => {
    ev.preventDefault();
    
    const data = new FormData();
    data.set("titulo", titulo);
    data.set("modalidad", modalidad);
    data.set("lugar", lugar);
    data.set("fecha", fecha);
    data.set("hora", hora);
    data.set("resumen", resumen);
    data.set("descripcion", descripcion);
    data.set("url", url);
    data.set("id", id);
    if (imagen?.[0]) {
      data.set("imagen", imagen?.[0]);
    }

    data.set('rol',userInfo.role);

    const response = await fetch(
      "https://backend-dedica.onrender.com/evento/updateEvento",
      {
        method: "PUT",
        body: data,
        credentials: "include",
      }
    );

    if (response.ok) {
      mostrarAlertaEditar();
      navigate(routes.eventos);
    }
  };

  return (
    <>
      <div className="FormularioEvento__seccion">
        <Form
          className="FormularioEvento__formulario"
          onSubmit={editarEvento}
        >
          <h5>Crear Evento</h5>
          <Form.Group>
            <Form.Label>Titulo</Form.Label>
            <Form.Control
              type="text"
              value={titulo}
              onChange={ev=>setTitulo(ev.target.value)}
            />
          </Form.Group>
          <Form.Group className="FormularioEvento__modalidadLugarFechaHora">
            <Form.Group>
              <Form.Label>Modalidad</Form.Label>
              <Form.Select value={modalidad}
              onChange={ev=>setModalidad(ev.target.value)}>
                <option></option>
                <option value="virtual">Virtual</option>
                <option value="presencial">Presencial</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Lugar</Form.Label>
              <Form.Select value={lugar}
              onChange={ev=>setLugar(ev.target.value)}>
                <option></option>
                {lugares.map((lugar) => (
                  <option key={lugar}>{lugar}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Fecha</Form.Label>
              <Form.Control type="date" value={fecha}
              onChange={ev=>setFecha(ev.target.value)}></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Hora</Form.Label>
              <Form.Control
                type="text"
                value={hora}
              onChange={ev=>setHora(ev.target.value)}></Form.Control>
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Label>Resumen</Form.Label>
            <Form.Control
              type="summary"
              placeholder="Resumen"
              value={resumen}
              onChange={ev=>setResumen(ev.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Imagen</Form.Label>
            <Form.Control type="file" onChange={ev=>setImagen(ev.target.files)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Enlace de evento (opcional) </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enlace"
              value={url}
              onChange={ev=>setUrl(ev.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Descripción completa (opcional) </Form.Label>
            <Form.Control as="textarea" rows={6} value={descripcion}
              onChange={ev=>setDescripcion(ev.target.value)} />
          </Form.Group>
          <Form.Group>
            <Button variant="secondary" onClick={editarEvento}>
              Editar Evento
            </Button>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

export default EditarEvento;
