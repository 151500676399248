import { Modal, Form, Button, Alert } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { useContext, useEffect } from "react";
import ValidarEditarCuenta from "../../../../validaciones/Usuarios/ValidarEditarCuenta";
import { roles } from "../../../../helpers/roles";
import { UserContext } from "../../../../Context/UserContextProvider";

const EditarCuentaModal = ({ isOpen, close,  }) => {
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: ValidarEditarCuenta,
  });
  const { errors, dirtyFields } = formState;

  const {  userInfo,setUserInfo } = useContext(UserContext);

  //handleSubmit Sirve tambien para construir la funcion que envia el los datos que se ingresaron en el form
  //register es un objeto de atributos
  //formData son los datos enviados en el formulario

  const isDirty = !!Object.keys(dirtyFields).length;

  const editarUsuario = async (formData) => {
    if (!isDirty) return;


    const data = {
      id: userInfo.id,
      username: formData.name,
      email: formData.email,
    };

    
    
    const response = await fetch("https://backend-dedica.onrender.com/user/updateAccount", {
      method: "PUT",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      credentials:'include'
    });
    
    

    if (response.ok) {
      const userInfo=await response.json();
      setUserInfo(userInfo);
      close();
    }
  };

  useEffect(() => {
     
    if (userInfo) {
      reset({
        name: userInfo.username,
        email: userInfo.email,
      });
    }
  }, []);

  

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  
  useEffect(() => {
    
  }, [userInfo])
  

  return (
    <>
      <Modal show={isOpen} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Editar mi cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(editarUsuario)}>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                placeholder="Escribe un nombre"
                {...register("name")}
                type="text"
              />
              {errors?.name && (
                <Form.Text>
                  <Alert variant="danger">{errors.name.message}</Alert>
                </Form.Text>
              )}
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Escribe un correo electrónico"
                {...register("email")}
                type="email"
              />
              {errors?.email && (
                <Form.Text>
                  <Alert variant="danger">{errors.email.message}</Alert>
                </Form.Text>
              )}
            </Form.Group>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            disabled={!isDirty}
            onClick={handleSubmit(editarUsuario)}
          >
            Actualizar mi cuenta
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditarCuentaModal;
