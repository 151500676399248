import { routes } from "../../routers/routes";

export const PrincipalBarraNavegacion__elementos = [
  {
   
    title: "Nosotros",
    url: routes.nosotros,
    subItems: [
      {
        
        title:'Proposito',
        url:routes.proposito,
        cName:'NavBar__subitem'
      },
      {
        
        title:'Liderazgo',
        url:routes.liderazgo,
        cName:'NavBar__subitem'
      },
      {
        
        title:'Miembros',
        url:routes.miembros,
        cName:'NavBar__subitem'
      },
      {
        
        title:'Estructura',
        url:routes.estructura,
        cName:'NavBar__subitem'
      },
      
      
    ],
    cName: "navBar__item",
    icon:"fas fa-caret-down"
  },
  {
    
    title: "Estrategias",
    url: routes.estrategias,
    subItems: [
      {
       
        title:'OSIL',
        url:routes.osil,
        cName:'NavBar__subitem'
      },
      {
        
        title:'Eventos',
        url:routes.eventos,
        cName:'NavBar__subitem'
      },
      {
        title:'Desarrollo de Proyectos',
        url:routes.desarrollo_de_Proyectos,
        cName:'NavBar__subitem'
      },
      {
        title:'Posicionamiento de Talento',
        url:routes.posicionamiento_de_Talento,
        cName:'NavBar__subitem'
      },
    
    ],
    cName: "navBar__item",
    icon:"fas fa-caret-down"
  },
  {
    title: "Aliados",
    url: routes.aliados,
    subItems: [
      {
        title:'Comunidades Académicas y Científicas Internacionales',
        url:routes.Comunidades_Académicas_y_Científicas_Internacionales,
        cName:'NavBar__subitem'
      },
      {
        title:'Empresas y Compañías globales',
        url:routes.Empresas_y_Compañías_globales,
        cName:'NavBar__subitem'
      },
      {
        title:'Fundaciones y Organizaciones No Gubernamentales',
        url:routes.Fundaciones_y_Organizaciones_No_Gubernamentales,
        cName:'NavBar__subitem'
      },
      {
        title:'Líderes reconocidos y especializados',
        url:routes.Líderes_reconocidos_y_especializados,
        cName:'NavBar__subitem'
      },
      
    ],
    cName: "navBar__item",
    icon:"fas fa-caret-down"
  },
  {
    title: "Innovacion",
    url: routes.innovacion,
    subItems: [
      {
        title:'Tecnologias',
        url:routes.tecnologias,
        cName:'NavBar__subitem'
      },
      {
        title:'Tendencias',
        url:routes.tendencias,
        cName:'NavBar__subitem'
      },
      {
        title:'Desarrollo Tecnológico',
        url:routes.desarrollo_Tecnologico,
        cName:'NavBar__subitem'
      },
    ],
    cName: "navBar__item",
    icon:""
  },
  {
    title: "Beneficiarios",
    url: routes.beneficiarios,
    subItems: [
      {
        title:'Industria',
        url:routes.industriaBeneficiarios,
        cName:'NavBar__subitem'
      },
      {
        title:'Gobierno',
        url:routes.gobierno,
        cName:'NavBar__subitem'
      },
      
    ],
    cName: "navBar__item",
    icon:"fas fa-caret-down"
  },
  
  
];
