export const agenda2=[
    {
        horas:'9:00-10:00',
        localizacion:'Auditorio',
        contenido:'Gtk, una alternativa para desarrollo de aplicaciones Gnome',
        orador:'Adlair Cerecedo',
        origen:'LibreOffice'
    },
    {
        horas:'10:00-11:00',
        localizacion:'Auditorio',
        contenido:'Comunidades FOSS pilar de la innovación colaborativa',
        orador:'Manuel Haro',
        origen:'Fundación DEDICA'
    },
    {
        horas:'11:00-11:30',
        localizacion:'Auditorio',
        contenido:'Proyectos de Sonoralibre del Gobierno del Estado',
        orador:'Daniel Chenoweth',
        origen:'Gobierno del Estado'
    },
    {
        horas:'11:00-11:30',
        localizacion:'Audiovisual',
        contenido:'La ciencia detrás de chatGPT/virtual',
        orador:'Dr. Oscar Miguel Cumbicus Pineda ',
        origen:'Universidad de Nacional de Loja de Ecuador'
    },
    {
        horas:'11:30-12:00',
        localizacion:'Auditorio',
        contenido:'El futuro es hoy',
        orador:'Abraham Duarte Ruiz',
        origen:'IT Support Specialist en Amazon'
    },
    {
        horas:'11:30-12:00',
        localizacion:'Audiovisual',
        contenido:'Specialized Talk',
        orador:'',
        origen:''
    },
    {
        horas:'12:00-12:30',
        localizacion:'',
        contenido:'Break',
        orador:'',
        origen:''
    },
    {
        horas:'12:30-13:00',
        localizacion:'Auditorio',
        contenido:'La parte social del open source',
        orador:'Miguel Alejandro Salgado Zapién',
        origen:'Moderador del GULTIJ Líder Técnico en AccionLabs'
    },
    {
        horas:'12:30-13:00',
        localizacion:'Audiovisual',
        contenido:'Porque desarrollar en Blockchain? Contratos inteligentes y dApps',
        orador:'Ayrton Klassen',
        origen:'OSIL / Nativo Crypto'
    },
    {
        horas:'13:00-13:30',
        localizacion:'Auditorio',
        contenido:'El lado obscuro de los consumidores en Youtube y la IA',
        orador:'Samuel Gonzalez Lopez',
        origen:'Universidad Tecnológica de Nogales'
    },
    {
        horas:'13:00-13:30',
        localizacion:'Audiovisual',
        contenido:'Chat-GPT como Complemento a mi trabajo',
        orador:'Jesús Guerra',
        origen:'Aviada'
    },
    {
        horas:'13:30-14:00',
        localizacion:'Auditorio',
        contenido:'El Camino a recorrer para convertirse en un buen Hacker',
        orador:'Adlair Cerecedo',
        origen:'Libreoffice'
    },
    {
        horas:'13:30-14:00',
        localizacion:'Audiovisual',
        contenido:'Importancia de Quality and Assurance en proyectos tecnológicos',
        orador:'Oscar Vidal',
        origen:'Aviada'
    },
    {
        horas:'14:00-16:00',
        localizacion:'',
        contenido:'Break',
        orador:'',
        origen:''
    },
    {
        horas:'Taller Practico 16:00-19:00',
        localizacion:'OSIL UTH',
        contenido:'Fundamentos de Ciberseguridad',
        orador:'Ing. Ruben Osbaldo Aguayo Donjuan',
        origen:'UT Hermosillo'
    },
    {
        horas:'Taller Practico 16:00-19:00',
        localizacion:'Laboratorio 1 UTH',
        contenido:'Empieza a colaborar con Git y GitHub',
        orador:'Ing, Rubén G. López Guzmán',
        origen:'Softtek'
    },
    {
        horas:'Taller Practico 16:00-19:00',
        localizacion:'Laboratorio 2 UTH',
        contenido:'Create a Development Environment on Alpine Linux',
        orador:'Ivan Chenoweth',
        origen:'UT Hermosillo'
    },
    {
        horas:'20:00-22:00',
        localizacion:'',
        contenido:'Cocktail de Networking y Cierre de LOTS',
        orador:'',
        origen:''
    },
    
]
