import React from "react";
import Seccion5 from "../../../components/secciones/seccion5/Seccion5";
import NavegacionAliados from "./NavegacionAliados";
import { useTranslation } from "react-i18next";

const EmpresasCompañíasGlobales = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionAliados titulo={t(`EmpresasCompañíasGlobales.Empresas y Compañías globales`)} />

      <Seccion5
        h2={t(`EmpresasCompañíasGlobales.Sector Privado, Industria y de Empresas`)} 
        p1={t(`EmpresasCompañíasGlobales.Fundacion Dedica`)} 
        p2={t(`EmpresasCompañíasGlobales.texto1`)} 
        p3={t(`EmpresasCompañíasGlobales.texto2`)} 
      />
    </>
  );
};

export default EmpresasCompañíasGlobales;
