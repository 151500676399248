import UserContextProvider from "./Context/UserContextProvider";
import AppRouter from "./routers/AppRouter";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import global_español from "./translations/español/global.json";
import global_ingles from "./translations/ingles/global.json";
import global_frances from "./translations/frances/global.json";
import global_portugués from "./translations/portugués/global.json";
import global_aleman from "./translations/aleman/global.json";
import global_lituano from "./translations/lituano/global.json";
import global_italiano from "./translations/italiano/global.json"


i18next.init({
  interpolation: { escapeValue: false },
  lng:"español",
  resources:{
    español:{
      global:global_español
    },
    ingles:{
      global:global_ingles
    },
    frances:{
      global:global_frances
    },
    portugués:{
      global:global_portugués
    },
    aleman:{
      global:global_aleman
    },
    lituano:{
      global:global_lituano
    },
    italiano:{
      global:global_italiano
    }
    
  }

});

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18next}>
        <UserContextProvider>
          <AppRouter />
        </UserContextProvider>
      </I18nextProvider>
    </div>
  );
}

export default App;
