import React from "react";
import "./TituloSecundario.css";

const TituloSecundario = ({ encabezado, texto }) => {
  return (
    <div className="TituloSecundario">
      <div className="TituloSecundario__contenedor">
        <h2>{encabezado}</h2>
        <div className="TituloSecundario__lineaDivisora"></div>
        <p>{texto}</p>
      </div>
    </div>
  );
};

export default TituloSecundario;
