export const laboratorios = [
  "ITESM Zacatecas",
  "CIMAT",
  "Tec Pabellón",
  "Tec de Naranjos",
  'UT de Durango',
  'Tec de El Llano',
  'CUCEA',
  'ITSON Obregon',
  'UT Nogales',
  'UT Hermosillo'
];
