import React, { useEffect, useState } from "react";
import Usuario from "./components/Usuario/Usuario";
import RegistrarUsuarioModal from "./components/RegistrarUsuarioModal";
import useModal from "../../../hooks/useModal";

import "./Usuarios.css";

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);

  const [
    isOpenRegistrarUsuarioModalModal,
    openRegistrarUsuarioModalModal,
    closeRegistrarUsuarioModalModal,
  ] = useModal();

  const traerUsuarios = async () => {
    const response = await fetch("https://backend-dedica.onrender.com/user/users", {
      method: "GET",
      credentials:'include'
    });

    const usuarios = await response.json();

    setUsuarios(usuarios);
  };

  useEffect(() => {
    traerUsuarios();
  }, []);

  return (
    <>
      <div className="Usuarios">
        <div className="Usuarios__titulo__boton">
          <h1>Lista de Usuarios</h1>
          <button
            onClick={openRegistrarUsuarioModalModal}
            className="Usuarios__botonRegistrar"
          >
            Registrar Usuario
          </button>
          <RegistrarUsuarioModal
            traerUsuarios={traerUsuarios}
            isOpen={isOpenRegistrarUsuarioModalModal}
            close={closeRegistrarUsuarioModalModal}
          />
        </div>
        <table className="Usuarios__tabla">
          <thead>
            <tr>
            <th>Usuario</th>
            <th>Correo Electronico</th>
            <th>Rol</th>
            <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.length > 0 &&
              usuarios.map((usuario) => <Usuario key={usuario._id} {...usuario} traerUsuarios={traerUsuarios} />)}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Usuarios;
