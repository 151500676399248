import React from "react";
import NavegacionEstrategias from "../../NavegacionEstrategias";
import LandingPageEvento from "../../../../../components/landingPageEvento/LandingPageEvento";
import { oradores } from "./oradores";
import TituloPrincipal from "../../../../../components/secciones/tituloPrincipal/TituloPrincipal";
import { agenda1 } from "./agenda1";
import { agenda2 } from "./agenda2";

const LOTS = () => {
  return (
    <>
      <NavegacionEstrategias
        titulo={"Eventos"}
        informacion={"Evento LOTS"}
      />
      <TituloPrincipal encabezado={'Latam Open Source Tech Summit'}/>
      
      <LandingPageEvento p1={'Hoy en día el posicionamiento de las tecnologías abiertas ( Software Libre y de Código Abierto) es cada vez amplio e incluso son el pilar sobre el cual se desplantan las más novedosas tendencias tecnológicas a nivel global como la inteligencia artificial, el cómputo en la nube, la ciberseguridad, tecnologías blockchain, la Web 3.0, ambientes inteligentes y desarrollo de software entre muchos más.'} 
        p2={'LOTS es un evento internacional que se creó y se organiza con la finalidad de estructurar una agenda que ofrezca a todos los sectores productivos un escenario real de la innovación, las tendencias tecnológicas, las soluciones digitales y las tendencias en cuanto la evolución tecnológica a nivel global. Integrando Conferencias Magistrales de reconocidos líderes internacionales como Jon Maddog Hall, Neil McGovern, Corinto Meffe, Ramón Roche, Ramón Morales, Jesús Palomino, Ernesto Piedras, Mario Teza, Ricardo Fritsch, Federico Mena; conferencias técnicas y de especialización con expositores nacionales y regionales que comparten sus experiencias, proyectos y temáticas novedosas; un programa completo de Talleres Prácticos de Entrenamiento que permita a los asistentes actualizarse y aprender de tecnologías altamente eficientes y sobre todo de tendencia como machine learning, redes neuronales, ciencia de datos, ciberseguridad, blockchain, aplicaciones móviles, desarrollo ágil de software, sistemas Linux entre muchos otros. '}
        p3={'Nuestro Congreso (LOTS) se enfoca también a la generación de una comunidad de colaboración a través de actividades conexión y networking entre todos los participantes ( conferencistas, ponentes, talleristas y asistentes), se organiza de manera itinerante que permita que los contenidos y las actividades generen beneficio a diferentes regiones de nuestro País.'}
        img={require("../../../../../assets/img/eventoLOTS/cartel2.jpeg")}
        oradores={oradores}
        agenda1={agenda1}
        dia1={'Jueves 18 de Mayo de 2023'}
        agenda2={agenda2}
        dia2={'Viernes 19 de Mayo de 2023'}
        btn={'Registrate en LOTS 2023'}
        url={'https://registro.technovadlab.com/plataforma/login.html'}
        />
      
      
    </>
  );
};

export default LOTS;
