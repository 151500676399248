import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin,faFlipboard } from "@fortawesome/free-brands-svg-icons";
import "./EventoOradores.css";

const EventoOradores = ({ oradores }) => {
  return (
    <>
      <section className="Seccion__EventoOradores">
        <div className="Seccion__EventoOradores__contenedor">
          {oradores.map((orador) => {
            return (
              <div  className={orador.CnameContenedor} data-aos={orador.efecto}>
                <div className={orador.Cname}>
                  <div className="Seccion__EventoOradores__orador__imagen">
                    <img src={orador.imagen} alt=""   />
                  </div>
                  <div className="Seccion__EventoOradores__orador__informacion">
                    <h3>{orador.nombre}</h3>
                    <h4>{orador.ocupacion}</h4>
                    <p>{orador.descripcion}</p>
                    <div className="Seccion__EventoOradores__orador__redes">
                    {orador?.Linkedin && <a target="_blank" href={orador.Linkedin}>
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        className="icon"
                      ></FontAwesomeIcon>
                    </a>}
                    {orador?.Page && <a target="_blank" href={orador.Page}>
                      <FontAwesomeIcon
                        icon={faFlipboard}
                        className="icon"
                      ></FontAwesomeIcon>
                    </a>}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default EventoOradores;
