export const routes={
    home:'/',


    nosotros:'/Nosotros',
    proposito:'/Proposito',
    liderazgo:'/Liderazgo',
    miembros:'/Miembros',
    estructura:'/Estructura',


    estrategias:'/Estrategias',
    osil:'/OSIL',
    eventos:'/Eventos',
    lots:'/Eventos/LOTS',
    desarrollo_de_Proyectos:'/Desarrollo de Proyectos',
    posicionamiento_de_Talento:'/Posicionamiento de Talento',


    aliados:'/Aliados',
    Comunidades_Académicas_y_Científicas_Internacionales:'/Comunidades Académicas y Científicas Internacionales',
    Empresas_y_Compañías_globales:'/Empresas y Compañías globales',
    Fundaciones_y_Organizaciones_No_Gubernamentales:'/Fundaciones y Organizaciones No Gubernamentales',
    Líderes_reconocidos_y_especializados:'/Líderes reconocidos y especializados',

    innovacion:'/Innovacion',
    tecnologias:'/Tecnologias',
    tendencias:'/Tendencias',
    desarrollo_Tecnologico:'/Desarrollo Tecnologico',

    beneficiarios:'/Beneficiarios',
    industriaBeneficiarios:'/IndustriaBeneficiarios',
    gobierno:'/Gobierno',

    socios:'/Socios',

    contacto:'/Contacto',

    blog:'/Blog',
    contactos:'/Contactos',
    contactoDetalle:'/Contactos/:id',
    noticias:'/Noticias',
    login:'/Login',
    crearPublicacion:'/crearPublicacion',
    editarPublicacion:'/editar/:id',
    publicacion:'/publicacion/:id',
    crearEvento:'/crearEvento',
    editarEvento:'/editarEvento/:id',
    cuenta:'/Cuenta',

    admin:{
        users:'/admin/Usuarios'
    }


}