export const paises= ["Afganistán",
"Albania",
"Alemania",
"Andorra",
"Angola",
"Antigua y Barbuda",
"Arabia Saudita",
"Argelia",
"Argentina",
"Armenia",
"Australia",
"Austria",
"Azerbaiyán",
"Bahamas",
"Bangladés",
"Barbados",
"Baréin",
"Bélgica",
"Belice",
"Benín",
"Bielorrusia",
"Birmania",
"Bolivia",
"Bosnia y Herzegovina",
"Botsuana",
"Brasil",
"Brunéi",
"Bulgaria",
"Burkina Faso",
"Burundi",
"Bután",
"Cabo Verde",
"Camboya",
"Camerún",
"Canadá",
"Catar",
"Chad",
"Chile",
"China",
"Chipre",
"Ciudad del Vaticano",
"Colombia",
"Comoras",
"Corea del Norte",
"Corea del Sur",
"Costa de Marfil",
"Costa Rica",
"Croacia",
"Cuba",
"Dinamarca",
"Dominica",
"Ecuador",
"Egipto",
"El Salvador",
"Emiratos Árabes Unidos",
"Eritrea",
"Eslovaquia",
"Eslovenia",
"España",
"Estados Unidos",
"Estonia",
"Etiopía",
"Filipinas",
"Finlandia",
"Fiyi",
"Francia",
"Gabón",
"Gambia",
"Georgia",
"Ghana",
"Granada",
"Grecia",
"Guatemala",
"Guyana",
"Guinea",
"Guinea ecuatorial",
"Guinea-Bisáu",
"Haití",
"Honduras",
"Hungría",
"India",
"Indonesia",
"Irak",
"Irán",
"Irlanda",
"Islandia",
"Islas Marshall",
"Islas Salomón",
"Israel",
"Italia",
"Jamaica",
"Japón",
"Jordania",
"Kazajistán",
"Kenia",
"Kirguistán",
"Kiribati",
"Kuwait",
"Laos",
"Lesoto",
"Letonia",
"Líbano",
"Liberia",
"Libia",
"Liechtenstein",
"Lituania",
"Luxemburgo",
"Madagascar",
"Malasia",
"Malaui",
"Maldivas",
"Malí",
"Malta",
"Marruecos",
"Mauricio",
"Mauritania",
"México",
"Micronesia",
"Moldavia",
"Mónaco",
"Mongolia",
"Montenegro",
"Mozambique",
"Namibia",
"Nauru",
"Nepal",
"Nicaragua",
"Níger",
"Nigeria",
"Noruega",
"Nueva Zelanda",
"Omán",
"Países Bajos",
"Pakistán",
"Palaos",
"Palestina",
"Panamá",
"Papúa Nueva Guinea",
"Paraguay",
"Perú",
"Polonia",
"Portugal",
"Reino Unido",
"República Centroafricana",
"República Checa",
"República de Macedonia",
"República del Congo",
"República Democrática del Congo",
"República Dominicana",
"República Sudafricana",
"Ruanda",
"Rumanía",
"Rusia",
"Samoa",
"San Cristóbal y Nieves",
"San Marino",
"San Vicente y las Granadinas",
"Santa Lucía",
"Santo Tomé y Príncipe",
"Senegal",
"Serbia",
"Seychelles",
"Sierra Leona",
"Singapur",
"Siria",
"Somalia",
"Sri Lanka",
"Suazilandia",
"Sudán",
"Sudán del Sur",
"Suecia",
"Suiza",
"Surinam",
"Tailandia",
"Tanzania",
"Tayikistán",
"Timor Oriental",
"Togo",
"Tonga",
"Trinidad y Tobago",
"Túnez",
"Turkmenistán",
"Turquía",
"Tuvalu",
"Ucrania",
"Uganda",
"Uruguay",
"Uzbekistán",
"Vanuatu",
"Venezuela",
"Vietnam",
"Yemen",
"Yibuti",
"Zambia",
"Zimbabue"]

export const tipoConsulta = [
  {
    id: 1,
    consulta: "Proyecto",
  },
  {
    id: 2,
    consulta: "Alianza",
  },
  {
    id: 3,
    consulta: "OSIL: Open Source Innovation Lab",
  },
  {
    id: 4,
    consulta: "Apoyos",
  },
  {
    id: 5,
    consulta: "Eventos",
  },
];

/*
<option value="">- Elige el país -</option>


                    <option value="DE">Alemania</option>

                    <option value="AD">Andorra </option>

                    <option value="AO">Angola </option>

                    <option value="AI">Anguila</option>

                    <option value="MK">
                      Antigua República Yugoslava de Macedonia{" "}
                    </option>

                    <option value="AG">Antigua y Barbuda </option>

                    <option value="AN">Antillas Holandesas </option>

                    <option value="AQ">Antártida </option>

                    <option value="SA">Arabia Saudita </option>

                    <option value="DZ">Argelia</option>

                    <option value="AR">Argentina </option>

                    <option value="AM">Armenia </option>

                    <option value="AW">Aruba</option>

                    <option value="AU">Australia </option>

                    <option value="AT">Austria</option>

                    <option value="AZ">Azerbaiyán </option>

                    <option value="BS">Bahamas</option>

                    <option value="BH">Bahrein </option>

                    <option value="BD">Bangladesh</option>

                    <option value="BB">Barbados</option>

                    <option value="BY">Belarús </option>

                    <option value="BZ">Belice</option>

                    <option value="BJ">Benin</option>

                    <option value="BM">Bermudas</option>

                    <option value="BO">Bolivia</option>

                    <option value="BA">Bosnia y Herzegovina </option>

                    <option value="BW">Botswana</option>

                    <option value="BR">Brasil</option>

                    <option value="BN">Brunei Darussalam</option>

                    <option value="BG">Bulgaria</option>

                    <option value="BF">Burkina Faso</option>

                    <option value="BI">Burundi</option>

                    <option value="BT">Bután </option>

                    <option value="BE">Bélgica </option>

                    <option value="CV">Cabo Verde</option>

                    <option value="KH">Camboya </option>

                    <option value="CM">Camerún </option>

                    <option value="CA">Canadá </option>

                    <option value="TD">Chad</option>

                    <option value="CL">Chile</option>

                    <option value="CN">China</option>

                    <option value="CY">Chipre</option>

                    <option value="CO">Colombia</option>

                    <option value="KM">Comoras</option>

                    <option value="CG">Congo</option>

                    <option value="CI">Costa de Marfil </option>

                    <option value="CR">Costa Rica</option>

                    <option value="HR">Croacia (Nombre Local: Hrvatska)</option>

                    <option value="CU">Cuba</option>

                    <option value="DK">Dinamarca</option>

                    <option value="DM">Dominica</option>

                    <option value="EC">Ecuador</option>

                    <option value="EG">Egipcia</option>

                    <option value="SV">El Salvador</option>

                    <option value="AE">Emiratos Árabes Unidos </option>

                    <option value="ER">Eritrea</option>

                    <option value="SK">Eslovaquia (República Eslovaca)</option>

                    <option value="SI">Eslovenia </option>

                    <option value="ES" selected="selected">
                      España
                    </option>

                    <option value="FM">Estados Federados de Micronesia </option>

                    <option value="US">Estados Unidos </option>

                    <option value="EE">Estonia</option>

                    <option value="ET">Etiopía </option>

                    <option value="RU">Federación rusa</option>

                    <option value="FJ">Fiji</option>

                    <option value="PH">Filipinas </option>

                    <option value="FI">Finlandia</option>

                    <option value="FR">Francia</option>

                    <option value="FX">Francia, Metropolitana </option>

                    <option value="GA">Gabón </option>

                    <option value="GM">Gambia</option>

                    <option value="GE">Georgia</option>

                    <option value="GS">
                      Georgias del Sur, Islas Sandwich del Sur
                    </option>

                    <option value="GH">Ghana</option>

                    <option value="GI">Gibraltar</option>

                    <option value="GD">Granada</option>

                    <option value="GR">Grecia</option>

                    <option value="GL">Groenlandia </option>

                    <option value="GP">Guadalupe</option>

                    <option value="GU">Guam</option>

                    <option value="GT">Guatemala</option>

                    <option value="GY">Guayana</option>

                    <option value="GF">Guayana Francesa</option>

                    <option value="GN">Guinea</option>

                    <option value="GQ">Guinea Ecuatorial</option>

                    <option value="GW">Guinea-Bissau </option>

                    <option value="HT">Haití </option>

                    <option value="HN">Honduras</option>

                    <option value="HK">Hong Kong</option>

                    <option value="HU">Hungría</option>

                    <option value="IN">India</option>

                    <option value="ID">Indonesia</option>

                    <option value="IQ">Irak</option>

                    <option value="IE">Irlanda</option>

                    <option value="IR">Irán (República Islámica de)</option>

                    <option value="BV">Isla Bouvet </option>

                    <option value="NF">Isla Norfolk </option>

                    <option value="IS">Islandia </option>

                    <option value="KY">Islas Caimán </option>

                    <option value="CC">Islas Cocos ( Keeling) </option>

                    <option value="CK">Islas Cook</option>

                    <option value="FK">Islas Falkland (Malvinas)</option>

                    <option value="FO">Islas Feroe </option>

                    <option value="HM">Islas Heard y Mc Donald</option>

                    <option value="MP">Islas Marianas del Norte</option>

                    <option value="MH">Islas Marshall</option>

                    <option value="SB">Islas Salomón</option>

                    <option value="SJ">Islas Svalbard y Jan Mayen</option>

                    <option value="TC">Islas Turcas y Caicos </option>

                    <option value="VI">Islas Vírgenes ( EE.UU. )</option>

                    <option value="VG">Islas Vírgenes (británicas)</option>

                    <option value="WF">Islas Wallis y Futuna </option>

                    <option value="IL">Israel</option>

                    <option value="IT">Italia</option>

                    <option value="LY">Jamahiriya Árabe Libia </option>

                    <option value="JM">Jamaica</option>

                    <option value="JP">Japón </option>

                    <option value="JO">Jordania</option>

                    <option value="KZ">Kazajstán </option>

                    <option value="KE">Kenia</option>

                    <option value="KG">Kirguistán </option>

                    <option value="KI">Kiribati</option>

                    <option value="KW">Kuwait </option>

                    <option value="CX">La Isla de Navidad</option>

                    <option value="CD">
                      La República Democrática del Congo{" "}
                    </option>

                    <option value="UM">
                      Las Islas menores alejadas de los Estados Unidos{" "}
                    </option>

                    <option value="LS">Lesoto</option>

                    <option value="LV">Letonia </option>

                    <option value="LR">Liberia</option>

                    <option value="LI">Liechtenstein </option>

                    <option value="LT">Lituania </option>

                    <option value="LU">Luxemburgo </option>

                    <option value="LB">Líbano </option>

                    <option value="MO">Macau</option>

                    <option value="MG">Madagascar</option>

                    <option value="MW">Malawi</option>

                    <option value="MY">Malaysia</option>

                    <option value="MV">Maldivas</option>

                    <option value="MT">Malta</option>

                    <option value="ML">Malí </option>

                    <option value="MA">Marruecos </option>

                    <option value="MQ">Martinica </option>

                    <option value="MU">Mauricio </option>

                    <option value="MR">Mauritania </option>

                    <option value="YT">Mayotte </option>

                    <option value="MN">Mongolia</option>

                    <option value="ME">Montenegro</option>

                    <option value="MS">Montserrat </option>

                    <option value="MZ">Mozambique </option>

                    <option value="MM">Myanmar </option>

                    <option value="MX">México </option>

                    <option value="MC">Mónaco </option>

                    <option value="NA">Namibia</option>

                    <option value="NR">Nauru</option>

                    <option value="NP">Nepal </option>

                    <option value="NI">Nicaragua</option>

                    <option value="NG">Nigeria</option>

                    <option value="NU">Niue</option>

                    <option value="NO">Noruega</option>

                    <option value="NC">Nueva Caledonia</option>

                    <option value="NZ">Nueva Zelanda</option>

                    <option value="NE">Níger </option>

                    <option value="OM">Omán </option>

                    <option value="PK">Pakistán </option>

                    <option value="PW">Palau</option>

                    <option value="PA">Panama</option>

                    <option value="PG">Papúa Nueva Guinea </option>

                    <option value="PY">Paraguay</option>

                    <option value="NL">Países Bajos</option>

                    <option value="PE">Perú </option>

                    <option value="PN">Pitcairn </option>

                    <option value="PF">Polinesia Francesa</option>

                    <option value="PL">Polonia</option>

                    <option value="PT">Portugal</option>

                    <option value="PR">Puerto Rico</option>

                    <option value="QA">Qatar</option>

                    <option value="GB">Reino Unido </option>

                    <option value="CF">República Centroafricana </option>

                    <option value="CZ">República Checa </option>

                    <option value="KR">República de Corea </option>

                    <option value="MD">República de Moldavia</option>

                    <option value="LA">
                      República Democrática Popular Lao
                    </option>

                    <option value="DO">República Dominicana</option>

                    <option value="KP">
                      República Popular Democrática de Corea{" "}
                    </option>

                    <option value="TZ">República Unida de Tanzania</option>

                    <option value="SY">República Árabe Siria</option>

                    <option value="RE">Reunión</option>

                    <option value="RW">Ruanda</option>

                    <option value="RO">Rumanía</option>

                    <option value="WS">Samoa</option>

                    <option value="AS">Samoa Americana </option>

                    <option value="KN">San Cristóbal y Nieves </option>

                    <option value="SM">San Marino</option>

                    <option value="PM">San Pedro y Miquelón</option>

                    <option value="VC">San Vicente y las Granadinas </option>

                    <option value="LC">Santa Lucía </option>

                    <option value="VA">Santa Sede (Ciudad del Vaticano)</option>

                    <option value="ST">Santo Tomé y Príncipe </option>

                    <option value="SN">Senegal</option>

                    <option value="RS">Serbia</option>

                    <option value="SC">Seychelles </option>

                    <option value="SL">Sierra Leona </option>

                    <option value="SG">Singapur </option>

                    <option value="SO">Somalia</option>

                    <option value="LK">Sri Lanka</option>

                    <option value="SH">St. Helena </option>

                    <option value="ZA">Sudáfrica </option>

                    <option value="SD">Sudán </option>

                    <option value="SE">Suecia</option>

                    <option value="CH">Suiza</option>

                    <option value="SR">Suriname</option>

                    <option value="SZ">Swazilandia</option>

                    <option value="EH">Sáhara Occidental </option>

                    <option value="TH">Tailandia </option>

                    <option value="TW">Taiwán </option>

                    <option value="TJ">Tayikistán </option>

                    <option value="IO">
                      Territorio Británico del Océano Índico{" "}
                    </option>

                    <option value="TF">Territorios Franceses del Sur </option>

                    <option value="TL">Timor-Leste (Timor Oriental)</option>

                    <option value="TG">Togo</option>

                    <option value="TK">Tokelau </option>

                    <option value="TO">Tonga</option>

                    <option value="TT">Trinidad y Tobago </option>

                    <option value="TM">Turkmenistán</option>

                    <option value="TR">Turquía </option>

                    <option value="TV">Tuvalu</option>

                    <option value="TN">Túnez </option>

                    <option value="UA">Ucrania </option>

                    <option value="UG">Uganda</option>

                    <option value="UY">Uruguay</option>

                    <option value="UZ">Uzbekistán </option>

                    <option value="VU">Vanuatu </option>

                    <option value="VE">Venezuela</option>

                    <option value="VN">Vietnam</option>

                    <option value="YE">Yemen </option>

                    <option value="DJ">Yibuti</option>

                    <option value="YU">Yugoslavia</option>

                    <option value="ZM">Zambia</option>

                    <option value="ZW">Zimbabue </option>
*/
