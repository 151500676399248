import React, {useState} from "react";
import swal from 'sweetalert';
import "./CrearPublicacion.css";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Editor from "../components/Editor";
import { useForm } from "react-hook-form";
import { routes } from "../../../../../routers/routes";

import { useNavigate } from "react-router-dom";

const CrearPublicacion = () => {

  const { register, handleSubmit,formState  } = useForm({
    
  });

  const navigate = useNavigate();

  const [content,setContent] = useState('');

  const mostrarAlerta=()=>{
    swal({
      title: "Publicación creada con exito!",
      icon: "success",
      timer:3000
    });
    navigate(routes.blog);
  }

  const crearNuevoPost= async(formData)=>{

    const data= new FormData();
    data.set('titulo',formData.titulo);
    data.set('resumen',formData.resumen);
    data.set('contenido',content);
    data.set('documento',formData.documento[0]);
    

    
    
    const response= await fetch('https://backend-dedica.onrender.com/publicacion/createPublicacion',{
      method:'POST',
      body:data,
      credentials: 'include',
    });
   
   
    if (response.ok){
      mostrarAlerta();
      
    }


  }

  return (
    <>
      <div className="Publicaciones__seccion">
        <Form className="Publicaciones__formulario" onSubmit={handleSubmit(crearNuevoPost)}>
          <h5>Crear Publicación</h5>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Titulo</Form.Label>
            <Form.Control type="title" placeholder="Titulo" {...register("titulo")}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Resumen</Form.Label>
            <Form.Control type="summary" placeholder="Resumen"  {...register("resumen")}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control type="file" {...register("documento")}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Editor value={content} onChange={setContent}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Button variant="secondary" onClick={handleSubmit(crearNuevoPost)}>Crear Publicación</Button>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

export default CrearPublicacion;
