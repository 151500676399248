import React from "react";

import "./Seccion3.css";

const Seccion3 = ({ h2, p1, h3, p2, img1,img2 }) => {
  return (
    <>
      <div className="Seccion3">
        <div className="Seccion3__contenedor">
          <div className="Seccion3__informacion__imagen">
            <div className="Seccion3__informacion">
            <h1>{h2}</h1>
            <p>{p1}</p>
            </div>
            <div className="Seccion3__imagen">
            <img src={img1} alt="Logo_Dedica" />
            </div>
          </div>
          <div className="Seccion3__informacion__imagen">
            <div className="Seccion3__informacion">
            <h3>{h3}</h3>
            <p>{p2}</p>
            </div>
            <div className="Seccion3__imagen">
            <img src={img2} alt="opensourcelogo" id="imagenOpensource" />
            </div>
          </div>
          
          
        </div>
      </div>
    </>
  );
};

export default Seccion3;
