import { routes } from "../../routers/routes";


export const informacion=[
  {
    id:1,
    img:"Notario_Dedica.jpg",
    title:"Fundación",
    text:"Fundación DEDICA es un organismo zacatecano que nació con el propósito de impulsar el talento jóven, la innovación colaborativa, desarrollo digital y el conocimiento abierto.",
    btn:"Aprender Más",
    link:"https://www.liderempresarial.com/fundacion-dedica-el-catalizador-del-talento-joven/",
    Cname:'carrusel__imagenes'
  },
  {
    id:2,
    img:"city.jpg",
    title:"OSIL: Open Source Innovation Lab",
    text:"Innovación colaborativa, Catalización de Talento, Desarrollo Digital y Conocimiento Abierto",
    btn:"Aprender Más",
    link:"/",
    Cname:'carrusel__imagenes'
  },
  {
    id:3,
    img:"eventos/OSTT.jpg",
    title:"Open Source Techs Talks",
    btn:"Aprender Más",
    link:"/",
    Cname:'carrusel__imagenes'
  },
  {
    id:4,
    img:"eventos/GNOME2.png",
    title:"GNOME Latam",
    btn:"Aprender Más",
    link:"https://events.gnome.org/event/136/",
    Cname:'carrusel__imagenes__positionTop'
  },
  {
    id:5,
    img:"eventos/lots2.jpg",
    title:"Latam Open Source Techs Summit",
    text:"Hermosillo 2023",
    btn:"Aprender Más",
    link:routes.lots,
    Cname:'carrusel__imagenes__positionTop'
  },
]