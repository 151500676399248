import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Editor from "../components/Editor";
import { useNavigate, useParams } from "react-router";
import swal from "sweetalert";
import { UserContext } from "../../../../../Context/UserContextProvider";

const EditarPublicacion = () => {
  const [titulo, setTitulo] = useState("");
  const [resumen, setResumen] = useState("");
  const [imagen, setImagen] = useState("");
  const [content, setContent] = useState("");

  const { id } = useParams();

  const navigate = useNavigate();

  const {userInfo}= useContext(UserContext);

  const mostrarAlertaEditar=()=>{
    swal({
      title: "Se actualizo de manera exitosa la Publicación!",
      icon: "success",
      timer:3000
    });
  }

  const editarPublicacion = async () => {
    const response = await fetch(`https://backend-dedica.onrender.com/publicacion/getPublicacion/${id}`);

    const postInfo = await response.json();

    setTitulo(postInfo.titulo);
    setContent(postInfo.contenido);
    setResumen(postInfo.resumen);
  };

  useEffect(() => {
    editarPublicacion();
  }, []);

   const editarPost =async (ev) => {
    ev.preventDefault();

    const data = new FormData();
    data.set("titulo", titulo);
    data.set("resumen", resumen);
    data.set("contenido", content);
    data.set('id',id)
    if (imagen?.[0]) {
      data.set("imagen", imagen?.[0]);
    }

    data.set('rol',userInfo.role);

    const response =await  fetch("https://backend-dedica.onrender.com/publicacion/updatePublicacion", {
      method: "PUT",
      body: data,
      credentials:'include'
    });

    if (response.ok) {
      mostrarAlertaEditar();
      navigate("/publicacion/" + id);
    }
  };

  return (
    <>
      <div className="Publicaciones__seccion">
        <Form className="Publicaciones__formulario" onSubmit={editarPost}>
          <h5>Editar Publicación</h5>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Titulo</Form.Label>
            <Form.Control
              type="title"
              placeholder="Titulo"
              value={titulo}
              onChange={ev=>setTitulo(ev.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Resumen</Form.Label>
            <Form.Control
              type="summary"
              placeholder="Resumen"
              value={resumen}
              onChange={ev=>setResumen(ev.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Control
              type="file"
              onChange={ev=>setImagen(ev.target.files)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Editor value={content} onChange={setContent} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Button variant="secondary" onClick={editarPost}>Editar Publicación</Button>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

export default EditarPublicacion;
