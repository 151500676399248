import React from "react";
import { useLocation } from "react-router";
import Seccion4 from "../../../components/secciones/seccion4/Seccion4";
import NavegacionAliados from "./NavegacionAliados";
import { useTranslation } from "react-i18next";

const ComunidadesAcadémicasCientíficasInternacionales = () => {

  const location=useLocation();

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionAliados
        titulo={t('seccionComunidadesAcadémicasCientíficasInternacionales.Comunidades Académicas y Científicas Internacionales')}
      />

      <Seccion4
        p1={t('seccionComunidadesAcadémicasCientíficasInternacionales.Fundacion Dedica')}
        h2={t('seccionComunidadesAcadémicasCientíficasInternacionales.Comunidades Académicas y Científicas Internacionales')}
        p2={t('seccionComunidadesAcadémicasCientíficasInternacionales.texto1')}
        p3={t('seccionComunidadesAcadémicasCientíficasInternacionales.texto2')}
        p4={t('seccionComunidadesAcadémicasCientíficasInternacionales.texto3')}
        img={require("../../../assets/img/macedonia.jpeg")}
      />
    </>
  );
};

export default ComunidadesAcadémicasCientíficasInternacionales;
