import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/UserContextProvider";
import "./MiniBarraNavegacion.css";
import menu from "../../assets/svg/menu.svg";
import {
  Elementos_SesiónIniciada__admin,
  Elementos_SesiónIniciada__moderador,
  Elementos_SinIniciarSesión,
} from "./MiniBarraNavegacion__elementos";
import { routes } from "../../routers/routes";
import { useTranslation } from "react-i18next";

const MiniBarraNavegacion = () => {

  
  const { userInfo, setUserInfo } = useContext(UserContext);
  const navigate = useNavigate();


  
  const [t,i18n]=useTranslation("global");

  const [value, setValue] = useState();

  const mostrarAlerta = () => {
    swal({
      title: "Cerro sesión con exito",
      icon: "success",
      timer: 2000,
    });
  };

  useEffect(() => {
    fetch("https://backend-dedica.onrender.com/api/auth/getCuenta", {
      credentials: "include",
    }).then((response) => {
      response.json().then((userInfo) => {
        setUserInfo(userInfo);
      });
    });
  }, []);

  const logout = async () => {
    const response = await fetch(
      "https://backend-dedica.onrender.com/api/auth/logout",
      {
        method: "POST",
        headers: {
          SameSite: "None",
        },
        credentials: "include",
      }
    );
    if (response.ok) {
      setUserInfo(null);
      mostrarAlerta();
      navigate(routes.home);
    }
  };

  const email = userInfo?.email;

  const role = userInfo?.role;

  return (
    <>
      <nav className="miniBarraNavegacion">
        <div className="miniBarraNavegacion__contenedor">
          <label
            htmlFor="miniBarraNavegacion__checkbox"
            className="miniBarraNavegacion__label"
          >
            <img
              src={menu}
              alt=""
              className="miniBarraNavegacion__imagen__menu"
            />
          </label>
          <input
            type="checkbox"
            id="miniBarraNavegacion__checkbox"
            className="miniBarraNavegacion__input"
          />
          <div className="miniBarraNavegacion__lista">
          
            <ul className="miniBarraNavegacion__elementos">
            <select className="miniBarraNavegacion__idiomas" value={value} onChange={(e) => setValue(e.currentTarget.value)}  onClick={()=>i18n.changeLanguage(value)}>
                <option value="español" >Español</option>
                <option value="ingles" >English</option>
                <option value="frances" >Français</option>
                <option value="portugués">Português</option>
                <option value="aleman">Deutsch</option>
                <option value="lituano">Lietuvių</option>
                <option value="italiano">Italiano</option>
              </select> 
              {email && role === "admin" && (
                <>
                  {Elementos_SesiónIniciada__admin.map((item) => {
                    return (
                      <li key={item.title}>
                        <Link to={item.url}>{t(`mininavbar.${item.title}`)}</Link>   
                      </li> 
                    );
                  })}
                  <li>
                    <button onClick={logout}>{t(`mininavbar.Cerrar Sesion`)}</button>
                  </li>
                </>
              )}
              {email && role === "moderador" && (
                <>
                  {Elementos_SesiónIniciada__moderador.map((item) => {
                    return (
                      <li key={item.title}>
                        <Link to={item.url}>{t(`mininavbar.${item.title}`)}</Link>
                      </li>
                    );
                  })}
                  <li>
                    <button onClick={logout}>{t(`mininavbar.Cerrar Sesion`)}</button>
                  </li>
                </>
              )}
              {!email &&
                Elementos_SinIniciarSesión.map((item) => {
                  return (
                    <li key={item.title}>
                      <Link to={item.url}>{t(`mininavbar.${item.title}`)}</Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MiniBarraNavegacion;
