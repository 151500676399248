
import { useEffect } from "react";
import { Alert, Form, Button,Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { roles } from "../../../../helpers/roles";
import swal from "sweetalert";
import ValidarNuevaUsuario from "../../../../validaciones/Usuarios/ValidarNuevaUsuario";
const RegistrarUsuarioModal = ({ isOpen, close, user,traerUsuarios }) => {
  const { register, handleSubmit, formState, reset } = useForm({
    resolver: ValidarNuevaUsuario,
  });
  const { errors,dirtyFields } = formState;

  const mostrarAlertaRegistro=()=>{
    swal({
      title: "Se registro de manera exitosa el usuario!",
      icon: "success",
      timer:3000
    });
  }


  const mostrarAlertaErrorPermisoRegistro=()=>{
    swal({
      title: "Error!",
      text:'Acción no permitida',
      icon: "error",
      button:'Aceptar'
    });
  }


  //handleSubmit Sirve tambien para construir la funcion que envia el los datos que se ingresaron en el form
  //register es un objeto de atributos
  //formData son los datos enviados en el formulario

  const isDirty= !!Object.keys(dirtyFields).length;

   const onSubmit =async (formData) => {
    
    if(!isDirty) return;
    
    
      const response=await fetch('https://backend-dedica.onrender.com/api/auth/signup',{
        method:'POST',
        body:JSON.stringify(formData),
        headers:{'Content-Type':'application/json'},
        credentials:'include'
      })
    
    if(response.status===200){
      mostrarAlertaRegistro();

    }else if(response.status===403){
      mostrarAlertaErrorPermisoRegistro();
      
    }
    traerUsuarios();
    close();
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen,reset]);

  useEffect(() => {
    if (user) {
      reset({
        name: user.name,
        email: user.email,
        role: user.role,
      });
    }
  }, [user,reset]);

  return (
    <>
      <Modal show={isOpen} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Registrar Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                placeholder="Escribe un nombre"
                {...register("name")}
                type="text"
              />
              {errors?.name && (
                <Form.Text>
                  <Alert variant="danger">{errors.name.message}</Alert>
                </Form.Text>
              )}
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                placeholder="Escribe un correo electrónico"
                {...register("email")}
                type="email"
              />
              {errors?.email && (
                <Form.Text>
                  <Alert variant="danger">{errors.email.message}</Alert>
                </Form.Text>
              )}
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                placeholder="Escribe una nueva contraseña"
                {...register("password")}
                type='password'
              />
              {errors?.password && (
                <Form.Text>
                <Alert variant="danger">
                    {errors.password.message}
                    </Alert> 
              </Form.Text>
              )}
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Rol</Form.Label>
              <Form.Control as={"select"}  {...register("roles")}>
                {Object.keys(roles).map(role=>(
                  <option key={role}>{role}</option>
                ))}
              </Form.Control>
              {errors?.role && (
                <Form.Text>
                  <Alert variant="danger">{errors.role.message}</Alert>
                </Form.Text>
              )}
            </Form.Group>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="primary" disabled={!isDirty} onClick={handleSubmit(onSubmit)}>
            Registrar Usuario
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegistrarUsuarioModal;
