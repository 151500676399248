import React, { useEffect, useState } from 'react'

import './ContactoDetalle.css'
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router';

const ContactoDetalle = () => {

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [compañia, setCompañia] = useState("");
  const [cargo, setCargo] = useState("");
  const [pais, setPais] = useState("");
  const [email, setEmail] = useState("");
  const [consulta, setConsulta] = useState("");
  const [descripcion, setDescripcion] = useState("");

  

  const { id } = useParams();


  const traerDatos= async()=>{
    const response=await fetch(`https://backend-dedica.onrender.com/contacto/getSolicitud/${id}`,{
      method: "GET",
    credentials:'include',

    }) 
  
    const contactoInfo= await response.json();
    setNombre(contactoInfo.nombre);
    setApellido(contactoInfo.apellido);
    setCompañia(contactoInfo.compañia);
    setCargo(contactoInfo.cargo);
    setPais(contactoInfo.pais);
    setEmail(contactoInfo.email);
    setConsulta(contactoInfo.consulta);
    setDescripcion(contactoInfo.descripcion);
  }


  useEffect(() => {
    traerDatos();

  
    
  }, [])
  
  

  return (
    <>
    
    <section className="contacto" id="contacto">
        <div className="contacto__fondo">
          <div className="contacto__contenedor">
            <div className="contacto__cabecera">
              <h3>Detalles de Solicitud</h3>
              <hr></hr>
              
            </div>
            <Form
              className="contacto__formulario"
            >
              <Form.Group className="contacto__informacion">
                <div className="contacto__campo">
                  <Form.Label>
                  Nombre<span>*</span>
                  </Form.Label>

                  
                  <Form.Control
                    type="text"
                    value={nombre}
                    disabled
                  />
                  
                </div>
                <div className="contacto__campo">
                  <Form.Label>
                  Apellido <span>*</span>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    value={apellido}
                    disabled
                  />
                  
                </div>
              </Form.Group>
              <Form.Group className="contacto__informacion">
                <div className="contacto__campo">
                  <Form.Label>
                  Compañía <span>*</span>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    value={compañia}
                    disabled
                  />
                 
                </div>
                <div className="contacto__campo">
                  <Form.Label>
                  Cargo <span>*</span>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    value={cargo}
                    disabled
                  />
                  
                </div>
              </Form.Group>
              <Form.Group className="contacto__informacion">
                <div className="contacto__campo">
                  <Form.Label>
                  País <span>*</span>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    value={pais}
                    disabled
                  />
                  
                </div>
                <div className="contacto__campo">
                  <Form.Label>
                    Email <span>*</span>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    value={email}
                    disabled
                  />
                  
                </div>
              </Form.Group>
              <Form.Group className="contacto__campo__consulta">
                <Form.Label>
                Consulta <span>*</span>
                </Form.Label>
                <Form.Control
                id="tipo-consulta"
                    type="text"
                    value={consulta}
                    disabled
                  />
                
              </Form.Group>
              <Form.Group className="contacto__campo__consulta">
                <Form.Label>
                Descripcion <span>*</span>
                </Form.Label>

                <Form.Control
                as={'textarea'}
                id="consulta-descripcion"
                    type="text"
                    value={descripcion}
                    disabled
                  />
                
              </Form.Group>
              
            </Form>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactoDetalle