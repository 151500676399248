import React, { useContext, useState } from "react";

import "./SeccionEvento.css";
import ModalEvento from "../ModalEvento/ModalEvento";
import { UserContext } from "../../../Context/UserContextProvider";
import { Link, useNavigate } from "react-router-dom";

import trash from "../../../assets/svg/trahs.svg";
import swal from "sweetalert";
import { routes } from "../../../routers/routes";

const SeccionEvento = ({
  _id,
  titulo,
  resumen,
  modalidad,
  lugar,
  imagen,
  hora,
  fecha,
  descripcion,
  url,
  autor,
}) => {
  const [show, setShow] = useState(false);
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  const mostrarAlertaEliminar = () => {
    swal({
      title: "Eliminación exitosa de Evento!",
      icon: "success",
      timer: 3000,
    });
  };

  const eliminarEvento = async () => {
    const response = await fetch(
      `https://backend-dedica.onrender.com/evento/deleteEvento/${_id}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (response.ok) {
      mostrarAlertaEliminar();
      window.location.reload();
    }
  };

  return (
    <>
      <section className="SeccionEvento">
        <div className="SeccionEvento__contenedor">
          <div className="SeccionEvento__imagen__lugarFecha">
            <div className="SeccionEvento__img">
              <img src={imagen} />
            </div>
            <div className="SeccionEvento__fechaLugar">
              <p>
                Modalidad: {modalidad} / Lugar: {lugar}
              </p>
              <span>
                Fecha: {fecha} / Hora: {hora} hrs
              </span>
            </div>
            <div className="SeccionEvento__btn__responsive">
              <button onClick={() => setShow(true)}>Mas Información</button>
            </div>
          </div>

          <div className="SeccionEvento__textoIntroductorio__btn">
            <h2>{titulo}</h2>
            <p>{resumen}</p>

            <div className="SeccionEvento__botones">
              <div className="SeccionEvento__btn">
                {!url && (
                  <button onClick={() => setShow(true)}>Mas información</button>
                )}
                {url && (
                  <a target="_blank" href={url}>
                    Mas información
                  </a>
                )}
              </div>
              {(userInfo?.id === autor?._id) | (userInfo?.role === "admin") && (
                <div className="SeccionEvento__botonesEditarEliminar">
                  <Link
                    className="SeccionEvento__botones__btnEditar"
                    to={`/editarEvento/${_id}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </Link>

                  <button
                    onClick={() => eliminarEvento()}
                    className="SeccionEvento__botones__btnEliminar"
                  >
                    <img src={trash} alt="" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <ModalEvento
          show={show}
          setShow={setShow}
          titulo={titulo}
          contenido={descripcion}
        />
      </section>
    </>
  );
};

export default SeccionEvento;
