import React from "react";
import "./EventoInformacion.css";

const EventoInformacion = ({ p1, p2, p3,img }) => {
  return (
    <>
      <section className="Seccion__EventoInformacion">
        <div className="Seccion__EventoInformacion__contenedor">
          <div className="Seccion__EventoInformacion__informacion" data-aos="fade-right">
            <p>{p1}</p>
            <p>{p2}</p>
            <p>{p3}</p>
          </div>
          <div  className="Seccion__EventoInformacion__cartel" data-aos="fade-left">
            <img
              src={img}
              alt=""
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default EventoInformacion;
