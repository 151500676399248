import React from "react";

import { Link, useLocation } from "react-router-dom";

import "./ImagenBarraNavegacion.css";
import { useTranslation } from "react-i18next";

const ImagenBarraNavegacion = ({ h1, p, nav_item1, nav_item2, nav_item3, nav_item4,nav_item5,nav_item6 }) => {
  let location = useLocation();

  const [t,i18n]=useTranslation("global");
  

  return (
    <>
      <section className="ImagenBarraNavegacion">
        <h1 className="ImagenBarraNavegacion__titulo">{h1}</h1>
        <p className="ImagenBarraNavegacion__informacion">{p}</p>

        <nav>
          <ul className="ImagenBarraNavegacion__lista">
            <Link
              className={
                location.pathname === `/${nav_item1}`
                  ? "ImagenBarraNavegacion__elementos selected"
                  : "ImagenBarraNavegacion__elementos"
              }
              to={`/${nav_item1}`}
            >
              {t(`navbarImagen.${nav_item1}`)}
            </Link>
            <Link
              className={
                location.pathname === `/${nav_item2}`
                  ? "ImagenBarraNavegacion__elementos selected"
                  : "ImagenBarraNavegacion__elementos"
              }
              to={`/${nav_item2}`}
            >
              {t(`navbarImagen.${nav_item2}`)}
            </Link>
            <Link
              className={
                location.pathname === `/${nav_item3}`
                  ? "ImagenBarraNavegacion__elementos selected"
                  : "ImagenBarraNavegacion__elementos"
              }
              to={`/${nav_item3}`}
            >
             {t(`navbarImagen.${nav_item3}`)}
            </Link>
            <Link
              className={
                location.pathname === `/${nav_item4}`
                  ? "ImagenBarraNavegacion__elementos selected"
                  : "ImagenBarraNavegacion__elementos"
              }
              to={`/${nav_item4}`}
            >
              {t(`navbarImagen.${nav_item4}`)}
            </Link>
          </ul>
        </nav>
      </section>
    </>
  );
};

export default ImagenBarraNavegacion;
