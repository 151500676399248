import React from "react";
import Seccion6 from "../../../components/secciones/seccion6/Seccion6";
import NavegacionEstrategias from "./NavegacionEstrategias";
import { useTranslation } from "react-i18next";

const DessarrolloProyectos = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionEstrategias
        titulo={t(`seccionDesarrolloProyectos.Desarrollo de Proyectos`)}
        informacion={t(`seccionDesarrolloProyectos.Seccion de Desarrollo de Proyectos`)}
      />

      <Seccion6 h2={t(`seccionDesarrolloProyectos.Desarrollo de Proyectos de Innovación Tecnológica`)}
      p1={t(`seccionDesarrolloProyectos.texto1`)}
      p2={t(`seccionDesarrolloProyectos.texto2`)}
      p3={t(`seccionDesarrolloProyectos.texto3`)}
      img1={require('../../../assets/img/learn_startup.png')}
      img2={require('../../../assets/img/know_your_costumer.png')}
      />
    </>
  );
};

export default DessarrolloProyectos;
