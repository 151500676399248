import React from "react";

import "./SeccionContacto.css";

import { tipoConsulta, paises } from "./informacionContacto";
import { useForm } from "react-hook-form";
import ValidarFormularioContactos from "../../validaciones/Contactos/ValidarFormularioContactos";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const SeccionContacto = () => {

  const [t,i18n]=useTranslation("global");

  const { register, handleSubmit, reset, formState } = useForm({
    resolver: ValidarFormularioContactos,
  });

  const {errors} =formState;

  const mostrarAlerta = () => {
    swal({
      title: "Solicitud enviada con exito!",
      icon: "success",
      timer: 3000,
    });
    reset();
  };

  const mandarSolicitud = async (formData) => {
    console.log(formData);

    const response = await fetch("https://backend-dedica.onrender.com/contacto/createSolicitud", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: { "Content-Type": "application/json" },
    });

    if (response.ok) {
      mostrarAlerta();
      reset();
    }
  };

  return (
    <>
      <section className="contacto" id="contacto">
        <div className="contacto__fondo">
          <div className="contacto__contenedor">
            <div className="contacto__cabecera">
              <h3>{t(`seccionContacto.Contacta con nosotros`)}</h3>
              <hr></hr>
              <p>
              {t(`seccionContacto.Integrate a nuestra fundacion y fortalece nuestras estrategias`)}
              </p>
            </div>
            <Form
              className="contacto__formulario"
              onSubmit={handleSubmit(mandarSolicitud)}
            >
              <Form.Group className="contacto__informacion">
                <div className="contacto__campo">
                  <Form.Label>
                  {t(`seccionContacto.Nombre`)} <span>*</span>
                  </Form.Label>

                  <Form.Control
                    placeholder="Nombre"
                    {...register("nombre")}
                    type="text"
                  />
                  {errors?.nombre && (
                    <Form.Text>
                      <Alert variant="danger">{errors.nombre.message}</Alert>
                    </Form.Text>
                  )}
                </div>
                <div className="contacto__campo">
                  <Form.Label>
                  {t(`seccionContacto.Apellido`)} <span>*</span>
                  </Form.Label>

                  <Form.Control
                    placeholder="Apellido"
                    {...register("apellido")}
                    type="text"
                  />
                  {errors?.apellido && (
                    <Form.Text>
                      <Alert variant="danger">{errors.apellido.message}</Alert>
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="contacto__informacion">
                <div className="contacto__campo">
                  <Form.Label>
                  {t(`seccionContacto.Compañía`)} <span>*</span>
                  </Form.Label>

                  <Form.Control
                    as={"select"}
                    placeholder="Compañía"
                    {...register("compañia")}
                  >
                    <option hidden selected  value='Compañia'>{t(`seccionContacto.Compañía`)}</option>
                    <option>{t(`seccionContacto.Compañía`)}</option>
                    <option>{t(`seccionContacto.Institución`)}</option>
                    <option>{t(`seccionContacto.Organización`)}</option>
                    <option>{t(`seccionContacto.Otro`)}</option>
                  </Form.Control>
                  {errors?.compañia && (
                    <Form.Text>
                      <Alert variant="danger">{errors.compañia.message}</Alert>
                    </Form.Text>
                  )}
                </div>
                <div className="contacto__campo">
                  <Form.Label>
                  {t(`seccionContacto.Cargo`)} <span>*</span>
                  </Form.Label>

                  <Form.Control
                    placeholder="Cargo"
                    {...register("cargo")}
                    type="text"
                  />
                  {errors?.cargo && (
                    <Form.Text>
                      <Alert variant="danger">{errors.cargo.message}</Alert>
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="contacto__informacion">
                <div className="contacto__campo">
                  <Form.Label>
                  {t(`seccionContacto.País`)} <span>*</span>
                  </Form.Label>

                  <Form.Control
                    as={"select"}
                    required="true"
                    {...register("pais")}
                  >
                    <option hidden selected  value='País'>Pais</option>
                    {paises.map((pais) => {
                      return <option id="tipo-consulta">{pais}</option>;
                    })}
                  </Form.Control>
                  {errors?.pais && (
                    <Form.Text>
                      <Alert variant="danger">{errors.pais.message}</Alert>
                    </Form.Text>
                  )}
                </div>
                <div className="contacto__campo">
                  <Form.Label>
                    Email <span>*</span>
                  </Form.Label>

                  <Form.Control
                    placeholder="Email"
                    {...register("email")}
                    type="email"
                  />
                  {errors?.email && (
                    <Form.Text>
                      <Alert variant="danger">{errors.email.message}</Alert>
                    </Form.Text>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="contacto__campo__consulta">
                <Form.Label>
                {t(`seccionContacto.Tipo de Consulta`)} <span>*</span>
                </Form.Label>
                <Form.Control
                  as={"select"}
                  placeholder="Tipo de Consulta"
                  {...register("consulta")}
                >
                  <option hidden selected  value='consulta'>{t(`seccionContacto.Tipo de Consulta`)}</option>
                  {tipoConsulta.map((item) => {
                    return (
                      <option key={item.id} id="tipo-consulta">
                        {t(`seccionContacto.${item.consulta}`)}
                      </option>
                    );
                  })}
                </Form.Control>
                {errors?.consulta && (
                    <Form.Text>
                      <Alert variant="danger">{errors.consulta.message}</Alert>
                    </Form.Text>
                  )}
              </Form.Group>
              <Form.Group className="contacto__campo__consulta">
                <Form.Label>
                {t(`seccionContacto.Descripción de la Consulta`)} <span>*</span>
                </Form.Label>

                <Form.Control as={'textarea'}
                  placeholder="Descripción de la Consulta"
                  id="consulta-descripcion"
                  {...register("descripcion")}
                  type="text"
                ></Form.Control>
                 {errors?.descripcion && (
                    <Form.Text>
                      <Alert variant="danger">{errors.descripcion.message}</Alert>
                    </Form.Text>
                  )}
              </Form.Group>
              <div className="contacto__boton__contenedor">
                <Button onClick={handleSubmit(mandarSolicitud)}>
                {t(`seccionContacto.Contactar`)}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </section>
    </>
  );
};

export default SeccionContacto;
