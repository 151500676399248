import React from "react";

import "./Seccion1.css";
import { routes } from "../../../routers/routes";
import { Link } from "react-router-dom";

const Seccion1 = ({p1,h2,p2,p3,p4,btn,img}) => {
  return (
    <>
      <div className="Seccion1">
        <div className="Seccion1__contenedor">
        <div className="Seccion1__informacion">
          <p>{p1}</p>
          <h2>{h2}</h2>
          <p>
            {p2}
          </p>
          <p>
            {p3}
          </p>
          <p>{p4}</p>
          <div className="Seccion1__boton">
            <Link to={routes.contacto}
            >{btn}</Link>
          </div>
        </div>
        <div className="Seccion1__imagen">
          <img alt="Imagen" src={img}/>
        </div>
        </div>
      </div>
    </>
  );
};

export default Seccion1;
