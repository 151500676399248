import React from "react";

import "./NavBarPrincipal.css";
import logo from "../../../assets/img/dedicalogo.png";
import arrow from "../../../assets/svg/arrow.svg";
import menu from "../../../assets/svg/menu.svg";
import { routes } from "../../../routers/routes";
import { PrincipalBarraNavegacion__elementos } from "../PrincipalBarraNavegacion__elementos";
import { useTranslation } from "react-i18next";

const NavBarPrincipal = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <nav className="NavBarPrincipal">
        <div className="NavBarPrincipal__container">
          <div className="NavBarPrincipal__imagen">
            <a href={routes.home}>
              <img src={logo} alt="" />
            </a>
          </div>

          <label
            htmlFor="NavBarPrincipal__checkbox"
            className="NavBarPrincipal__label"
          >
            <img src={menu} alt="" className="NavBarPrincipal__imagen__menu" />
          </label>
          <input
            type="checkbox"
            id="NavBarPrincipal__checkbox"
            className="NavBarPrincipal__input"
          />

          <div className="NavBarPrincipal__menu">
            <ul className="NavBar__item__lista">
              {PrincipalBarraNavegacion__elementos.map((item) => {
                return (
                  <li key={item.title} className="NavBar__item__lista__elemento">
                    <div className="NavBar__item__lista__contenedor">
                      <a  className="NavBar__item" href={item.url}>
                          {t(`navbar.${item.title}`)}
                      </a>
                      <img className="NavBar__item__flecha" src={arrow} alt="" />
                    </div>
                    <ul className="NavBar__subitem__lista" >
                      {item.subItems.map((subitem) => {
                        return (
                          <li key={subitem.title} className="NavBar__subitem__lista__elemento">
                            <a className={subitem.cName} href={subitem.url}>
                            {t(`navbar.${subitem.title}`)}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              })}
              <li className="NavBar__item__lista__elemento">
                <a className="NavBar__item" href={routes.contacto}>
                {t(`navbar.Contacto`)}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBarPrincipal;
