import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
    nombre: yup
    .string("El nombre debe ser un texto")
    .matches(/^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/,'Ingresa un nombre valido, sin espacio al final')
    .required("Debe de ingresar un nombre")
    ,
    apellido: yup
    .string("EL apellido debe ser un texto")
    .matches(/^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/,'Ingresa un apellido valido, sin espacio al final')
    .required("Debe de ingresar el apellido"),
    compañia: yup
    .string("La compañia debe ser un texto")
    .matches(/^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/,'Ingresa un nombre valido, sin espacio al final')
    .required("Debe de ingresar el apellido"),
    cargo: yup
    .string("El cargo debe ser un texto")
    .matches(/^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/,'Ingresa un nombre valido, sin espacio al final')
    .required("Debe de ingresar el cargo"),
    pais: yup
    .string("El pais debe ser un texto")
    .matches(/^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/,'Ingresa un nombre valido, sin espacio al final')
    .required("Debe de ingresar el pais"),
    email: yup
    .string("El pais debe ser un texto")
    .matches(/[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/,'Ingresa un email valido')
    .required("Debe de ingresar el email")
    .email("Debe ingresar un email valido"),
    consulta: yup
    .string("El tipo de Consulta debe ser un texto")
    .matches(/^[\wÑñÁáÉéÍíÓóÚú ."@_,:]+\.?$/,'Ingrese solamente caracteres alfanumericos asi como numeros o caracteres especiales como @ , _ , ", coma, .')
    .required("Debe de ingresar el tipo de Consulta"),
    descripcion: yup
    .string("La descripcion debe ser un texto ")
    .matches(/^[\w\d\sÑñÁáÉéÍíÓóÚú ."@_,:]+\.?$/,'Ingrese solamente caracteres alfanumericos asi como numeros o caracteres especiales como @ , _ , ", coma, . , :')
    .required("Debes ingresar una descripcion")
    .min(10,'La descripcion debe tener al menos 10 carácteres')
    
    
});

export default yupResolver(schema);
