export const oradores=[
    {
        nombre:'Manuel Haro Márquez',
        ocupacion:'Presidente de Fundación Dedica',
        descripcion:'Mexicano apasionado de la tecnología y del software libre, académico comprometido con la Innovación Colaborativa y el impulso al talento en México. Analógico de nacimiento y nomada digital aprendiz de extraordinarios líderes.',
        imagen:require('../../../../../assets/img/eventoLOTS/Manuel.jpeg'),
        Cname:'Seccion__EventoOradores__orador__imagenTexto',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorIzquierda',
        Linkedin:'https://www.linkedin.com/in/manuel-haro-89631a56',
        efecto:'fade-right'
    },
    {
        nombre:'José Ramón Morales Ávalos',
        ocupacion:'CEO de Fundación Dedica',
        descripcion:'Líder de Innovación de Intel GDC por más de 20 años, CEO de Corporativo Ilimita Digital y Miembro de CANIETI Región Occidente. Cuenta con más de 20 años de carrera en entornos corporativos y de alta tecnología; persona de sólido liderazgo y trayectoria empresarial creando nuevos roles, tecnologías y mercados de valor agregado.',
        imagen:require('../../../../../assets/img/eventoLOTS/Ramón Morales.jpg'),
        Cname:'Seccion__EventoOradores__orador__textoImagen',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorDerecha',
        efecto:'fade-left'
    },
    {
        nombre:'Claudio Cossio',
        ocupacion:'ICP Latam Hub',
        descripcion:'Claudio es co-fundador de Nativo Crypto - ICP LATAM Hub y miembro fundador de Meta Pool DAO. Forma parte de la red de mentores de Seedcamp. Inversor ángel en HackerNoon, Rigs, LocalAdventures y MiDulceHogar. Le gusta trabajar en productos digitales y comunidades en protocolos blockchain como Internet Computer Protocol, Aurora, Ethereum y NEAR.',
        imagen:require('../../../../../assets/img/eventoLOTS/claudio.jpeg'),
        Cname:'Seccion__EventoOradores__orador__imagenTexto',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorIzquierda',
        Linkedin:'https://www.linkedin.com/in/ccossio/?originalSubdomain=mx',
        efecto:'fade-right'
    },
    {
        nombre:'Luis A. Castro',
        ocupacion:'ITSON & InnovationLabs',
        descripcion:'Luis Castro es investigador en Interacción-Humano Computadora. El Dr. Castro obtuvo su grado de Doctor en Informática en la Universidad de Manchester en el Reino Unido. Su grado de Maestro en Ciencias en Ciencias de la Computación fue otorgado por el Centro de Investigación Científica y de Educación Superior de Ensenada (CICESE). Sus intereses de investigación son interacción humano-computadora, cómputo ubicuo e informática médica y comunitaria.',
        imagen:require('../../../../../assets/img/eventoLOTS/Luis.jpg'),
        Cname:'Seccion__EventoOradores__orador__textoImagen',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorDerecha',
        efecto:'fade-left'
    },
    {
        nombre:'Alonso López',
        ocupacion:'UT Hermosillo',
        descripcion:'Egresado de Ingeniería en Sistemas Computacionales del Instituto Tecnológico de Hermosillo en el 2000, y con un gran interés en la formación y educación, ingreso a la Universidad Tecnológica de Hermosillo desde 2002, donde he sido miembro de la facultad en programas de Ingeniería en TICs, y programas educativos relacionados con la Informática desde entonces.',
        imagen:require('../../../../../assets/img/eventoLOTS/Alonso López.jpeg'),
        Cname:'Seccion__EventoOradores__orador__imagenTexto',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorIzquierda',
        efecto:'fade-left'
    },
    {
        nombre:'Iván Chenoweth',
        ocupacion:'UT Hermosillo',
        descripcion:'Soy Ingeniero en Electronica, egresado del Intituto Tecnologico de Hermosillo (1999-2004), con estudios de maestria en Consultoria Interna y Externa del Instituto Sistema Empresa Inteligente (2009-2010). Actualmente laboro como profesor de asignatura en la Universidad Tecnologica de Hermosillo desde 2009 a la fecha, y como Ingeniero de Software en 3pillar Global desde 2017.',
        imagen:require('../../../../../assets/img/eventoLOTS/Ivan.jpeg'),
        Cname:'Seccion__EventoOradores__orador__textoImagen',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorDerecha',
        Linkedin:'https://www.linkedin.com/in/ivanchenoweth/',
        efecto:'fade-right'
    },
    {
        nombre:'Samuel González López',
        ocupacion:'Universidad Tecnológica de Nogales',
        descripcion:'Profesor Titular C del Departamento de Tecnologías de la Información de la Universidad Tecnológica de Nogales, Sonora y colabora con el Departamento de Investigación del Instituto Tecnológico de Nogales. Desde 2018 es Investigador visitante en la University of Arizona at the School of Information. Sus líneas de investigación son el procesamiento del lenguaje natural, el aprendizaje automático para la minería de textos y el procesamiento del lenguaje para herramientas de evaluación automática de la escritura.',
        imagen:require('../../../../../assets/img/eventoLOTS/samuel.jpeg'),
        Cname:'Seccion__EventoOradores__orador__imagenTexto',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorIzquierda',
        Linkedin:'https://www.linkedin.com/in/samuel-gonz%C3%A1lez-l%C3%B3pez-56517410/?original_referer=&originalSubdomain=mx',
        efecto:'fade-left'
    },
    {
        nombre:'Abraham Duarte',
        ocupacion:'IT Support Specialist',
        descripcion:'Maestro en Ciencias de la Computación. Apasionado por las TICs, la Música, la Ciencia, la Astrofísica y la Astronomía. Trayectoria de más de 20 años en el ramo de las TICs. Programador aficionado en C#, PHP y Python. Académico por hobby, más que por profesión. Dispuesto a formar profesionales y no profesionistas.',
        imagen:require('../../../../../assets/img/eventoLOTS/Abraham.jpeg'),
        Cname:'Seccion__EventoOradores__orador__textoImagen',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorDerecha',
        Linkedin:'https://www.linkedin.com/in/abraham-duarte-ruiz-3255ab70/',
        efecto:'fade-right'
    },
    {
        nombre:'Daniel F. Chenoweth',
        ocupacion:'Gobierno del Estado',
        descripcion:'Auditor adscrito al órgano de control interno de isssteson (2011-2014), responsable de la base de datos del siiaf en la secretaria de hacienda (2015-2017), enlace administrativo del siiaf (2017-2019), desarrollo de sistemas de información para la dirección de desarrollo organizacional de la susbecretaria de recursos humanos adscrita a la secretaria de hacienda y como instructor interno en los cursos de informatica, excel, word y power point para el centro de capacitación del gobierno del estado (cecap) del 2020 al 2022.',
        imagen:require('../../../../../assets/img/eventoLOTS/daniel.jpeg'),
        Cname:'Seccion__EventoOradores__orador__imagenTexto',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorIzquierda',
        efecto:'fade-right'
    },
    {
        nombre:'Oscar Miguel Cumbicus Pineda',
        ocupacion:'Universidad de Nacional de Loja de Ecuador',
        descripcion:'Actualmente es profesor en la Universidad Nacional de Loja y estudiante de doctorado en la UPV, miembro principal de la comunidad SomosNLP. Fundador de Open Machine Learning Ecuador. Le apasiona la Inteligencia Artificial, especialmente cuando se trata de ayudar a las personas. Siempre intenta aprender cosas nuevas y formarse profesionalmente. Cree que la tecnología y la ciencia deberían utilizarse siempre para ayudar al bien común de las personas.',
        imagen:require('../../../../../assets/img/eventoLOTS/oscar.jpeg'),
        Cname:'Seccion__EventoOradores__orador__textoImagen',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorDerecha',
        efecto:'fade-left'
    },
    {
        nombre:'Miguel Alejandro Salgado Zapien',
        ocupacion:'Moderador del GULTIJ Líder Técnico en AccionLabs',
        descripcion:'Soy un usuario de software libre y open source desde hace más de 15 años, utilizándolo de manera personal y profesional, cuento con una educación en ciencias y participo y dirijo equipos técnicos de alto rendimiento. Fui profesor por dos años en la UTTijuana para las direcciones de TICS y Mecatrónica y Manufactura Aeronáutica.',
        imagen:require('../../../../../assets/img/eventoLOTS/miguel.jpeg'),
        Cname:'Seccion__EventoOradores__orador__imagenTexto',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorIzquierda',
        efecto:'fade-right'
    },
    {
        nombre:'Ruben Aguayo',
        ocupacion:'UT Hermosillo',
        descripcion:'Especialista en Redes y Telecomunicaciones. Amplia experiencia en Switching, Routing, Firewalls, Cableado estructurado, Video Colaboración, CCTV, Radioenlaces, Control de Acceso, Detección de Incendio, Telefonía Análoga, IP y SIP, Tecnologías Wifi, Equipos de Cómputo, Administración de Proyectos, Integrador, Capacitador, y Emprendedor. En la actualidad me encuentro enfocado en la Ciberseguridad, colaborando en el área de Infraestructura de TI.',
        imagen:require('../../../../../assets/img/eventoLOTS/ruben.jpeg'),
        Cname:'Seccion__EventoOradores__orador__textoImagen',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorDerecha',
        efecto:'fade-left'
    },
    {
        nombre:'Argel Franco Bejarano de la Cruz',
        ocupacion:'Flutter & Dart GDE | Writer, Speaker and Founder @esFlutter',
        descripcion:'Soy un ingeniero de software con más de 12 años de experiencia. He trabajado con muchos stacks de tencnologías para desarrollo, pero me he enfocado en particular sobre dispositivos móviles durante los últimos cinco años, creando aplicaciones para Android e iOS y en todos los demás lugares en los que Flutter me permite hacerlo, como la Web y el escritorio. Soy uno de los líderes de la comunidad de Dart y Flutter en español de México a Argentina y España.',
        imagen:require('../../../../../assets/img/eventoLOTS/Argel.jpeg'),
        Cname:'Seccion__EventoOradores__orador__imagenTexto',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorIzquierda',
        efecto:'fade-right'
    },
    {
        nombre:'Adlair Cerecedo Méndez',
        ocupacion:'LibreOffice',
        descripcion:'El Software Libre y Open Source ha sido mi actividad divulgativa por excelencia. Experimentar con “lo desconocido” me llevó a mi primer contacto con Linux a finales de los 90’s cuando probé Corel Linux, mismo que era distribuido por una conocida revista informática. Mi hacktivismo me ha ayudado a participar en la difusión de las tecnologías libres así como la colaboración para la mejora de éstas trabajando en distintos proyectos. Debido a mi gusto por la tecnología, he llegado a adquirir distintos gadgets y uno de mis hobbies es experimentar con ellos, también soy aportador activo de distintos proyectos de Software Libre y miembro de la Sociedad Mexicana de Física.',
        imagen:require('../../../../../assets/img/eventoLOTS/Adlair.jpeg'),
        Cname:'Seccion__EventoOradores__orador__textoImagen',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorDerecha',
        efecto:'fade-left'
    },
    {
        nombre:'Rubén G. López Guzmán',
        ocupacion:'UT Nogales',
        descripcion:'Ingeniero de software, desarrollador full stack .NET framework y Scrum Developer Certified con más de 8 años de experiencia. Desde 2019 hasta la fecha se desempeña como Ingeniero de Software en Softtek, empresa mexicana líder en la industria y actualmente condecorada como uno de los lugares top para trabajar en México 2023. Sus actividades dentro de Softtek  están enfocadas al Mercado americano y van desde el diseño, desarrollo, mantenimiento y pruebas de aplicaciones de .NET usadas en Reino Unido y USA en el sector salud, eso sin olvidar que está trabajando cómodamente de manera remota en su casa.',
        imagen:require('../../../../../assets/img/eventoLOTS/Rubén.jpeg'),
        Cname:'Seccion__EventoOradores__orador__imagenTexto',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorIzquierda',
        efecto:'fade-right'
    },
    {
        nombre:'Jorge Isaac Klassen',
        ocupacion:'OSIL / Nativo Crypto',
        descripcion:'Desarrollador web full-stack y entusiasta sobre temas de Blockchain y descentralización. Evangelista Web 3 y completista de videojuegos. Se ha desempeñado como instructor para distintos protocolos de Blockchain en distintos lenguajes y actualmente como desarrollador de tiempo completo.',
        imagen:require('../../../../../assets/img/eventoLOTS/Isaac.jpeg'),
        Cname:'Seccion__EventoOradores__orador__textoImagen',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorDerecha',
        efecto:'fade-left'
    },
    {
        nombre:'Hector Benitez',
        ocupacion:'Encora',
        descripcion:'Es un Software Developer que trabaja en Nearsoft dentro del equipo de R&D. Apasionado por la tecnología, el emprendimiento y la innovación. Constante promotor de las metodologías ágiles entre la comunidad de desarrollo. Sus tecnologías preferidas son Python, PHP y Javascript.',
        imagen:require('../../../../../assets/img/eventoLOTS/Hector.jpeg'),
        Cname:'Seccion__EventoOradores__orador__imagenTexto',
        CnameContenedor:'Seccion__EventoOradores__contenedor__oradorIzquierda',
        efecto:'fade-right'
    },

]