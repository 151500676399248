import React from "react";

import "./Seccion2.css";

const Seccion2 = ({h5,p1,p2,p3,btn,img}) => {

  const fondo={
    backgroundImage:`url(${img})`,
    
  }
  

  return (
    <>
      <section   style={fondo}  className="Seccion2" >
      
        <div className="Seccion2__gradiante">
          <div className="Seccion2__informacion">
          <h5>{h5}</h5>
          <p id="Seccion2__primerParrafo">{p1}</p>
          <p>{p2}</p>
          <p>{p3}</p>
          </div>
        </div>
        </section> 
    </>
  );
};

export default Seccion2;