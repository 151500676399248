//import React from "react";
import { useState } from "react";
import "./Faq.css";
import { useTranslation } from "react-i18next";

function Faq() {

  const [t,i18n]=useTranslation("global");
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }

    setSelected(i);
  };
  {t(`home_seccionPreguntas.Preguntas frecuentes`)}
  return (
    <div className="faq__wrapper">
      <div className="faq__container">
        <div className="faq__information">
          <div>
            <h2>{t(`home_seccionPreguntas.Preguntas frecuentes`)}</h2>
            <hr />
            <p>{t(`home_seccionPreguntas.Encuentre respuestas a las preguntas más frecuentes sobre Dedica`)}</p>
          </div>
        </div>
      </div>
      <div className="faq__accordion">
        {data.map((item, i) => (
          <div className="faq__item" key={item.id}>
            <div className="faq__title" onClick={() => toggle(i)}>
              <h2>{t(`home_seccionPreguntas.${item.question}`)}</h2>
              <span>{selected === i ? "X" : "|||"}</span>
            </div>
            <div className={selected === i ? "faq__content show" : "faq__content"}>
              {t(`home_seccionPreguntas.${item.answer}`)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
const data = [
  {
    id:1,
    question: "¿Qué es Dedica?",
    answer:
      "La Fundación para el Desarrollo Digital y el Conocimiento Abierto (DEDICA) es una idea que nace  de buscar una instancia autónoma, sin fines de lucro y no gubernamental que liderará las estrategias que surgían aprovechando el respaldo de todas fundaciones especializadas en desarrollo tecnológico sobre tecnologías abiertas como Gnome y Google, corporativos globales como Intel y Asociaciones de Software Libre como la de Brasil y la de Ecuador.",
  },
  {
    id:2,
    question: "Estrategias de la fundación OSIL",
    answer: "La Fundación DEDICA está estructurada de tal forma que se priorice el protagonismo, la participación, la colaboración y el acompañamiento de todos los que conforman la comunidad integrada tanto por instituciones de educación como de grandes aliados. De manera estratégica la Fundación cuenta con un Consejo Directivo que debe trabajar en la conformación de planes de acción, las estrategias de fortalecimiento para lograr la misión de la Fundación, concretar alianzas que la vinculen con todos los sectores productivos, establecer convenios de colaboración con instituciones académicas y en términos generales representar y liderar formal y legalmente a esta virtuosa Comunidad de Talento y Creatividad en México y Latinoamérica.",
  },
  {
    id:3,
    question: "¿Qué hace Dedica?",
    answer: "La estructura y la misión de la Fundación DEDICA se enfoca en consolidar un ecosistema de innovación tecnológica y transformación digital de manera multisectorial, de tal forma que instancias de cualquier sector pueden encontrar en la Fundación DEDICA y el Open Source Innovation Lab un gran aliado para resolver problemáticas así como crear ideas innovadoras a través de proyectos de tecnológicos.",
  },
  {
    id:4,
    question: "¿Cómo ayuda Dedica?",
    answer: "Las instituciones de educación medía superior y superior son el más importante colaborador de la Fundación que ofrece a todas estas comunidades académicas una instancia colaborativa donde sus estudiantes y recién egresados fortalezcan sus habilidades a través de experiencia colaborando en proyectos reales, actualicen sus conocimientos a través de programas de capacitación novedosos y ofrecer a los jovenes talentosos oportunidades reales de posicionamiento profesional.",
  },
  {
    id:5,
    question: "¿Qué alcance o que región opera la fundación?",
    answer: "Se cuenta ya con un plan de trabajo bastante completo conformado por una agenda importante de eventos organizados y liderados por la Fundación como la organización de eventos que impulsen la actualización de las universidades y sus comunidades académicas, que fomenten la creatividad para el diseño de ideas innovadoras en base a retos y concretar el liderazgo en la organización de eventos globales para llevarlos a cabo en México con la participación principalmente de la Comunidad en México. También ya se tienen consolidados los lanzamientos de 9 Sedes del Open Source Innovation Lab en Estados como Jalisco, Sonora, Aguascalientes, Veracruz, Chiapas, Durango, Coahuila, Nuevo León y Zacatecas; con la firme intensión de que dichas entidades se beneficien del Modelo de Innovación, de la Comunidad de Talento y del respaldo  de grandes aliados para impulsar la innovación tecnológica y la transformación digital catalizando el talento en México.",
  },
];

export default Faq;
