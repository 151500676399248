import React from "react";

import "./SeccionUniversidad.css";

import { Universidades } from "./Universidades";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SeccionUniversidad = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <section className="SeccionUniversidad">
        <div className="SeccionUniversidad__informacion">
          <div>
            <h2>{t(`home_seccionUniversidades.Comunidad Nacional de OSIL`)}</h2>
            <hr />
          </div>
        </div>
        <div className="SeccionUniversidad__universidades">
          {Universidades.map((item) => {
            return (
              <div key={item.cName} className="SeccionUniversidad__universidad">
                <a target="_blank" href={item.url}>
                <div className="SeccionUniversidad__universidad__imagen-titulo" >
                  <img
                  id={item.cName}
                    src={require(`../../../assets/img/Universities/${item.imagen}`)}
                    alt="/"
                    
                  /> 
                </div>
                </a>
              </div>
            );
          })}
        </div>
        <div className="SeccionUniversidad__boton__contenedor">
          <Link
            to="contacto"
          >
            {t(`home_seccionUniversidades.Ponerse en Contacto`)}
          </Link>
        </div>
      </section>
    </>
  );
};

export default SeccionUniversidad;
