import { formatISO9075 } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../../Context/UserContextProvider";
import trash from "../../../../../assets/svg/trahs.svg";
import swal from "sweetalert";

import "./Publicacion.css";
import { routes } from "../../../../../routers/routes";



const Publicacion = () => {
  const [postInfo, setPostInfo] = useState(null);
  const { userInfo } = useContext(UserContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const mostrarAlertaEliminar = () => {
    swal({
      title: "Eliminación exitosa de Publicación!",
      icon: "success",
      timer: 3000,
    });
  };

  const traerPublicacion = async () => {
    const response = await fetch(`https://backend-dedica.onrender.com/publicacion/getPublicacion/${id}`);
    const postInfo = await response.json();
    setPostInfo(postInfo);
  };

  const eliminarPublicacion = async () => {
    const response = await fetch(`https://backend-dedica.onrender.com/publicacion/deletePublicacion/${id}`, {
      method: "DELETE",
      credentials: "include",
    });

    if (response.ok) {
      mostrarAlertaEliminar();
      navigate(routes.blog);
    }
  };

  useEffect(() => {
    traerPublicacion();
  }, []);

  if (!postInfo) return "";

  return (
    <>
      <div className="PublicacionCompleta__fondo__titulo">
        <img src={postInfo.imagen} alt="" />
        <div className="PublicacionCompleta__fondo__titulo__informacion">
          <h1>{postInfo.titulo}</h1>
        </div>
      </div>

      <div className="PublicacionCompleta">
        <div className="PublicacionCompleta__contenedor">
        <div className="PublicacionCompleta__botones">
        {(userInfo?.id === postInfo.autor?._id | userInfo?.role==='admin' ) && (
          
            <>
              <Link className="PublicacionCompleta__botones__btnEditar" to={`/editar/${postInfo._id}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
             
            </Link>

            <button
              onClick={() => eliminarPublicacion()}
              className="PublicacionCompleta__botones__btnEliminar"
            >
              <img src={trash} alt="" /> 
            </button>
            </>
          
        )}
        </div>
          <div className="PublicacionCompleta__fecha">
            <time>
              Publicado el{" "}
              {formatISO9075(new Date(postInfo.createdAt), {
                representation: "date",
              })}
            </time>
            {/* <div className="author">by @{postInfo.autor.username}</div> */}
          </div>
          <div
            className="PublicacionCompleta__contenido"
            dangerouslySetInnerHTML={{ __html: postInfo.contenido }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Publicacion;
