export const Universidades = [
  {
    imagen:"UDG.jpeg",
    cName:"UDG",
    url:"https://www.cucea.udg.mx/"
  },
  {
    imagen:"TM.png",
    cName:"TM",
    url:'https://tec.mx/es'
  },
  {
    imagen:'UAZ.png',
    cName:"UAZ",
    url:'https://www.uaz.edu.mx/'
  },
  {
    imagen:"UNIR.png",
    cName:"UNIR",
    url:'https://www.google.com/search?q=unir+universidad&ei=kN9NZMXjF53KkPIPl8OXkAI&ved=0ahUKEwjF68OE1ND-AhUdJUQIHZfhBSIQ4dUDCBA&uact=5&oq=unir+universidad&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCAAQgAQyBQguEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQ6CAguEIAEENQCOggIABCABBCxAzoLCC4QigUQsQMQgwE6CwguEIAEELEDEIMBOhYILhCABBDUAhCXBRDcBBDeBBDgBBgBOgcILhCKBRBDOg0ILhCKBRDHARCvARBDOgcIABCKBRBDOgsILhCABBDHARCvAToVCC4QigUQQxCXBRDcBBDeBBDgBBgBOgoILhCKBRCxAxBDOgoIABCKBRCxAxBDOhMILhCKBRCxAxCDARDHARCvARBDOgsIABCABBCxAxCDAToYCC4QigUQsQMQQxCXBRDcBBDeBBDgBBgBOg4ILhCABBCxAxDHARCvAUoECEEYAFAAWOQqYLMsaABwAXgAgAGKAYgB6w6SAQQwLjE2mAEAoAEBwAEB2gEGCAEQARgU&sclient=gws-wiz-serp',
  },
  {
    imagen:"IEST.jpg",
    cName:"IEST",
    url:'https://www.anahuac.mx/iest/'
  },
  {
    imagen:"UTH.jpg",
    cName:"UTH",
    url:'http://www.uthermosillo.edu.mx/'
  },
  {
    imagen:"ITSON.png",
    cName:"ITSON",
    url:'https://www.itson.mx/Paginas/index.aspx'
  },
  {
    imagen:"UTR.jpg",
    cName:"UTR",
    url:'https://utr.edu.mx/'
  },
  {
    imagen:"UTNA.jpg",
    cName:"UTNA",
    url:'https://www.utna.edu.mx/utna/',
  },
  {
    imagen:"UPA.jpg",
    cName:"UPA",
    url:'https://upa.edu.mx/'
  },
  {
    imagen:"UT.jpg",
    cName:"UT",
    url:'https://www.utags.edu.mx/'
  },
  {
    imagen:"UTM.jpg",
    cName:"UTM",
    url:'https://utma.edu.mx/'
  },
  
  {
    imagen:"TS.jpg",
    cName:"TS",
    url:'https://tecdesoftware.mx/'
  },
  {
    imagen:"ITP.jpg",
    cName:"ITP",
    url:'https://www.pochutla.tecnm.mx/'
  },
  {
    imagen:"ITZ.jpg",
    cName:"ITZ",
    url:'https://zacatecas.tecnm.mx/'
  },
  {
    imagen:'UPZ.jpg',
    cName:"UPZ",
    url:'http://web.upz.edu.mx/'
  },
  {
    imagen:'ITSNa.jpg',
    cName:"ITSNa",
    url:'https://www.itsna.edu.mx/'
  },
  {
    imagen:'ITEL.png',
    cName:"ITEL",
    url:'https://llano.tecnm.mx/'
  },
  {
    imagen:'UTN.jpg',
    cName:"UTN",
    url:'http://www.utnogales.edu.mx/'
  },
  {
    imagen:'ITDZ.png',
    cName:"ITDZ",
    url:'https://www.iztapalapa.tecnm.mx/'
  },
  
  {
    imagen:"ITDPDA.png",
    cName:"ITDPDA",
    url:'http://pabellon.tecnm.mx/'
  },
  {
    imagen:'ITSSAT.jpg',
    cName:"ITSSAT",
    url:'https://www.itssat.edu.mx/'
  },

  
  
];
