import React from "react";
import NavegacionNosotros from "./NavegacionNosotros";
import Seccion7 from "../../../components/secciones/seccion7/Seccion7";
import { useTranslation } from "react-i18next";

const Nosotros = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionNosotros
        titulo={t('seccionNosotros.Nosotros')}
        informacion={t('seccionNosotros.Seccion de Nosotros')}
      />
      <Seccion7
        h2={t('seccionNosotros.Nosotros')+":"}
        p2={t('seccionNosotros.La Fundación DEDICA está estructurada de tal forma que se priorice el protagonismo, la participación, la colaboración y el acompañamiento de todos los que conforman la comunidad integrada tanto por instituciones de educación como de grandes aliados.')}
        p3={t('seccionNosotros.De manera estratégica la Fundación cuenta con un Consejo Directivo que debe trabajar en la conformación de planes de acción, las estrategias de fortalecimiento para lograr la misión de la Fundación, concretar alianzas que la vinculen con todos los sectores productivos, establecer convenios de colaboración con instituciones académicas y en términos generales representar y liderar formal y legalmente a esta virtuosa Comunidad de Talento y Creatividad en México y Latinoamérica.')}
        img={require('../../../assets/img/talento.jpg')}
      />
    </>
  );
};

export default Nosotros;
