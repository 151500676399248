import React from "react";

import "./Seccion5.css";

const Seccion5 = ({ p1, p2, p3,h2,}) => {
  return (
    <>
      <div className="Seccion5">
        <div className="Seccion5__seccion">
          
          <div className="Seccion5__informacion">
            <p>{p1}</p>
            <h2>{h2}</h2>
            <p>{p2}</p>
            <p>{p3}</p>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Seccion5;
