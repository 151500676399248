import React, { useContext, useState } from "react";

import useModal from "../../../../../hooks/useModal";

import "./Usuario.css";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditarUsuarioModal from "../EditarUsuarioModal";
import EliminarUsuarioModal from "../EliminarUsuarioModal";
import { UserContext } from "../../../../../Context/UserContextProvider";

const Usuario = ({ _id, username, email, role, traerUsuarios }) => {
  const { userInfo, setUserInfo } = useContext(UserContext);

  const [
    isOpenEliminarUsuarioModal,
    openEliminarUsuarioModal,
    closeEliminarUsuarioModal,
  ] = useModal();
  const [
    isOpenEditarUsuarioModal,
    openEditarUsuarioModal,
    closeEditarUsuarioModal,
  ] = useModal();

  return (
    <>
      {userInfo.username != username && (
        <tr className="Usuario__datos">
          <td>{username}</td>
          <td>{email}</td>
          <td>{role.name}</td>
          <td>
            <FontAwesomeIcon
              icon={faPenToSquare}
              onClick={openEditarUsuarioModal}
              id="icono__Editar"
            />{" "}
            |{" "}
            <FontAwesomeIcon
              icon={faTrash}
              onClick={openEliminarUsuarioModal}
              id="icono__Basura"
            />{" "}
            <EditarUsuarioModal
              user={{ _id, username, email, role }}
              traerUsuarios={traerUsuarios}
              isOpen={isOpenEditarUsuarioModal}
              close={closeEditarUsuarioModal}
            />
            <EliminarUsuarioModal
              id={_id}
              traerUsuarios={traerUsuarios}
              isOpen={isOpenEliminarUsuarioModal}
              close={closeEliminarUsuarioModal}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export default Usuario;
