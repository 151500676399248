import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { roles } from "../../helpers/roles";

const schema = yup.object().shape({
  name: yup
    .string("El nombre debe ser un texto")
    .matches(/^([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+)(\s+([A-Za-zÑñÁáÉéÍíÓóÚú]+['\-]{0,1}[A-Za-zÑñÁáÉéÍíÓóÚú]+))*$/,'Ingresa un nombre valido, sin espacio al final')
    .required("Debe de ingresar un nombre"),
    password: yup
    .string("La contraseña debe ser un texto ")
    .matches(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,'La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula. NO puede tener otros símbolos.')
    .required("Debes ingresar una contraseña"),
  email: yup
    .string("EL email debe ser un texto")
    .matches(/[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/,'Ingresa un email valido')
    .required("Debe de ingresar un email")
    .email("Debe ingresar un email valido"),
  role: yup.string("El rol debe ser un texto").oneOf(Object.keys(roles)),
});

export default yupResolver(schema);
