import { Link } from "react-router-dom";
import img1 from "../../../assets/img/eventos/GNOME2.png";

import "./SeccionBlog.css";
import { routes } from "../../../routers/routes";
import {  useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SeccionBlog = () => {

  const [t,i18n]=useTranslation("global");

  const [publicaciones, setPublicaciones] = useState({})

  const traerPosts = async () => {
    const response = await fetch(
      "https://backend-dedica.onrender.com/publicacion/getPublicaciones",
      {
        method: "GET",
      }
    );

    const posts = await response.json();
    setPublicaciones(posts);
    
  };

useEffect(() => {
    traerPosts();
}, [])


  return (
    <>
      <section className="SeccionBlog">
        <div className="SeccionBlog__informacion">
          <div>
            <h2>{t(`home_seccionBlog.Blog de la Fundación Dedica`)}</h2>
            <hr />
          </div>
        </div>
        <div className="SeccionBlog__contenedor">
          <div className="SeccionBlog__contenedor__izquierda">
            <div className="SeccionBlog__contenedor__izquierda__imagen">
              <img src={publicaciones[0]?.imagen} alt="" />
            </div>
            <div className="SeccionBlog__contenedor__izquierda__temaFecha">
              <div className="SeccionBlog__contenedor__izquierda__temaFecha__tema">
              <Link className="underline-hover" to={routes.blog}>Ir a Sección de Blog</Link>
              </div>
              <span></span>
            </div>
            <div className="SeccionBlog__contenedor__izquierda__descripcion">
              <h3>
                <Link className="underline-hover" to={routes.blog}>{publicaciones[0]?.titulo}</Link>
              </h3>
              <p>
               {publicaciones[0]?.resumen}
              </p>
              
            </div>
          </div>
          <div className="SeccionBlog__contenedor__derecha">
            <div className="SeccionBlog__contenedor__derecha__bloque">
              <div className="SeccionBlog__contenedor__derecha__imagen">
                <img src={publicaciones[1]?.imagen} alt="" />
              </div>
              <div className="SeccionBlog__contenedor__derecha__temaFecha">
                <div className="SeccionBlog__contenedor__derecha__temaFecha__tema">
                <Link className="underline-hover" to={routes.blog}>Ir a Sección de Blog</Link>
                </div>
                <span></span>
              </div>
              <div className="SeccionBlog__contenedor__derecha__titulo">
                <h3>
                <Link className="underline-hover" to={routes.blog}>{publicaciones[1]?.titulo}</Link>
                </h3>
              </div>
            </div>
            <div className="SeccionBlog__contenedor__derecha__bloque">
              <div className="SeccionBlog__contenedor__derecha__imagen">
                <img src={publicaciones[2]?.imagen} alt="" />
              </div>
              <div className="SeccionBlog__contenedor__derecha__temaFecha">
              <div className="SeccionBlog__contenedor__derecha__temaFecha__tema">
              <Link className="underline-hover" to={routes.blog}>Ir a Sección de Blog</Link>
                </div>
                <span></span>
              </div>
              <div className="SeccionBlog__contenedor__derecha__titulo">
                <h3>
                <Link className="underline-hover" to={routes.blog}>{publicaciones[2]?.titulo}</Link>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="SeccionBlog__boton">
          <Link to={routes.blog}>{t(`home_seccionBlog.Ver todas las publicaciones`)}</Link>
        </div>
      </section>
    </>
  );
};

export default SeccionBlog;
