import { Modal, Alert, Button } from "react-bootstrap";
import swal from "sweetalert";
const EliminarUsuarioModal = ({ isOpen, close, id, traerUsuarios }) => {
  const mostrarAlertaEliminar = () => {
    swal({
      title: "Se elimino de manera exitosa el usuario!",
      icon: "success",
      timer: 3000,
    });
  };

  const eliminarUsuario = async () => {
    const response = await fetch(`https://backend-dedica.onrender.com/user/${id}`, {
      method: "DELETE",
      credentials: "include",
    });

    if (response.ok) {
      traerUsuarios();
      close();
      mostrarAlertaEliminar();
    }
  };

  const handleDelete = () => {
    eliminarUsuario();
  };

  return (
    <>
      <Modal show={isOpen} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar Cuenta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            ¿Estas seguro que deseas eliminar permanentemente esta cuenta?
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Eliminar mi cuenta
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EliminarUsuarioModal;
