import React from 'react'
import ImagenBarraNavegacion from '../../../components/imagen-barra-navegacion/ImagenBarraNavegacion'

const NavegacionAliados = ({titulo,informacion}) => {
  return (
    <>
        <ImagenBarraNavegacion
        h1={titulo}
      />
    </>
  )
}

export default NavegacionAliados