import React from "react";
import NavegacionNosotros from "./NavegacionNosotros";
import Seccion4 from "../../../components/secciones/seccion4/Seccion4";
import { useTranslation } from "react-i18next";

const Liderazgo = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionNosotros titulo={t('seccionLiderazgo.Liderazgo')} informacion={t('seccionLiderazgo.Seccion de Liderazgo')}/>
      <Seccion4 p1={t('seccionLiderazgo.Consejo Directivo')}
        h2={t('seccionLiderazgo.Liderazgo')}
        p2={t('seccionLiderazgo.Con la firme intención de que está extraordinaria estrategia cuente con personas y líderes reconocidos en la Comunidad a nivel internacional, el Consejo Directivo lo conforman el Dr. José Ramón Morales Ávalos, Dr. Oscar Pérez Veyna y el Ing. Manuel Haro Márquez. Dr. José Ramón Morales Ávalos, más de 20 años de carrera en entornos corporativos y de alta tecnología. Sólido liderazgo y trayectoria empresarial creando nuevos roles, tecnologías y mercados de valor agregado; Líder de Innovación de Intel GDC por más de 2 décadas, CEO de Corporativo Ilimita Digital y Miembro de CANIETI Región Occidente.')}
        p3={t('seccionLiderazgo.Dr. Oscar Pérez Veyna; Docente Investigador de la UAZ, fundador y Director del Centro Universitario de Cómputo, miembro de la RUTyC, miembro de la Internet Society Capítulo México y líder de proyectos especializados en ciencia de datos. Ing. Manuel Haro; Fundador y Coordinador del Laboratorio de Software Libre, miembro de la Gnome Foundation, Docente Investigador de la UAZ, experiencia como conferencista en eventos internacionales especializados en tecnologías Software Libre y Open Source, miembro de la Comunidad de Software Libre de México y Latinoamérica.')}
        p4={t('seccionLiderazgo.La participación de las universidades, de corporativos y organizaciones no gubernamentales como aliados estratégicos es indispensable, todos los anteriores constituyen un pilar operativo como parte del Consejo Consultivo.')}
        img={require("../../../assets/img/liderazgo.jpg")}
     />
    </>
  );
};

export default Liderazgo;
