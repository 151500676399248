import React from 'react'
import NavegacionInnovacion from './NavegacionInnovacion'
import { useTranslation } from 'react-i18next';

const Innovación = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
        <NavegacionInnovacion titulo={t('seccionInnovacion.Innovación')} informacion={t("seccionInnovacion.Seccion de Innovación")}/>
       
    </>
  )
}

export default Innovación