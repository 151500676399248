import React, { useContext }  from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {UserContext} from "../../../Context/UserContextProvider";
import { routes } from "../../../routers/routes";
import swal from 'sweetalert';

import "./Login.css";
import { Alert, Button, Form } from "react-bootstrap";
import ValidarLogin from "../../../validaciones/Login/ValidarLogin";

const Login = () => {
  const { register, handleSubmit,formState,reset} = useForm({
    resolver:ValidarLogin,
  });

  const {setUserInfo}= useContext(UserContext);

  const mostrarAlerta=()=>{
    swal({
      title: "Credenciales Incorrectas!",
      icon: "error",
      button:'Aceptar'
    });
    reset();
  }

  const { errors, dirtyFields } = formState;

  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    const response = await fetch("https://backend-dedica.onrender.com/api/auth/signin", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include' 
    });

    if (response.ok) {
      
      const userInfo=await response.json();
      setUserInfo(userInfo);
      navigate(routes.crearPublicacion)
    } else {
      mostrarAlerta();
    }

    
  };

  return (
    <>
      <div className="Login__fondo">
        <div className="Login__contenedor">
          <div className="Login__izquierda"></div>
          <div className="Login__derecha">
            <h3>Iniciar Sesión</h3>

            <Form className="Login__derecha__formulario">
              <Form.Group className="Login__derecha__formulario__campo">
                <Form.Label>Correo:</Form.Label>
                <span>*</span>
                <Form.Control
                  placeholder="Correo"
                  {...register("email")}
                  type="text"
                  required
                />
                {errors?.email && (
                <Form.Text>
                  <Alert variant="danger">{errors.email.message}</Alert>
                </Form.Text>
              )}
              </Form.Group>
              <Form.Group className="Login__derecha__formulario__campo">
                <Form.Label>Contraseña:</Form.Label>
                <span>*</span>
                <Form.Control
                  placeholder="Contraseña"
                  {...register("password")}
                  type="password"
                  required
                />
                {errors?.password && (
                <Form.Text>
                  <Alert variant="danger">{errors.password.message}</Alert>
                </Form.Text>
              )}
              </Form.Group>

              <div className="Login__derecha__formulario__boton">
                <Button onClick={handleSubmit(onSubmit)}>Iniciar Sesión</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>

    </>
  );
};

export default Login;
