import React from "react";
import "./EventoAgenda.css";

const EventoAgenda = ({ titulo,agenda,dia }) => {
  return (
    <>
      <section className="Seccion__EventoAgenda">
        <div className="Seccion__EventoAgenda__contenedor">
          <div className="Seccion__EventoAgenda__titulo">
            <div>
            <span>{titulo}</span>
            <h2>{dia}</h2>
            </div>

            <div className="Seccion__EventoAgenda__lineaDivisora"></div>
          </div>
          <div className="Seccion__EventoAgenda__agenda">
            <table>
              <thead>
                <tr>
                  <th>Hora</th>
                  <th>Localización</th>
                  <th>Contenido</th>
                  <th>Orador</th>
                  <th>Origen</th>
                </tr>
              </thead>
              <tbody>
                {agenda.map((item) => {
                  return (
                    <tr>
                      <td id="hora">{item.horas}</td>
                      <td id="localizacion">{item.localizacion}</td>
                      <td id="contenido">{item.contenido}</td>
                      <td>{item.orador}</td>
                      <td>{item.origen}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventoAgenda;
