import React from "react";
import { Link } from "react-scroll";

import "./Seccion7.css";

const Seccion7 = ({p1,h2,p2,p3,p4,img}) => {
  return (
    <>
      <div className="Seccion7">
        <div className="Seccion7__contenedor">
        <div className="Seccion7__informacion">
          <p>{p1}</p>
          <h2>{h2}</h2>
          <p>
            {p2}
          </p>
          <p>
            {p3}
          </p>
          <p>{p4}</p>
          
        </div>
        <div className="Seccion7__imagen">
          <img alt="Imagen" src={img}/>
        </div>
        </div>
      </div>
    </>
  );
};

export default Seccion7
