import React from "react";
import ImagenBarraNavegacion from "../../../components/imagen-barra-navegacion/ImagenBarraNavegacion";
import { useTranslation } from "react-i18next";

const NavegacionBeneficiarios = ({ titulo, informacion }) => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <ImagenBarraNavegacion
        h1={titulo}
        p={informacion}
        nav_item1={t('seccionIndustria.Industria')}
        nav_item2={t('seccionGobierno.Gobierno')}
      />
    </>
  );
};

export default NavegacionBeneficiarios;
