import { routes } from "../../routers/routes";

export const Elementos_SesiónIniciada__admin = [
  {
    title: "Blog",
    url: routes.blog,
  },
  {
    title: "Solicitudes de Contacto",
    url: routes.contactos,
  },
  {
    title: "Publicar",
    url: routes.crearPublicacion,
  },
  {
    title: "Crear Evento",
    url: routes.crearEvento,
  },
  {
    title: "Usuarios",
    url: routes.admin.users,
  },
  {
    title: "Cuenta",
    url: routes.cuenta,
  },
];

export const Elementos_SesiónIniciada__moderador = [
  {
    title: "Blog",
    url: routes.blog,
  },
  {
    title: "Publicar",
    url: routes.crearPublicacion,
  },
  {
    title: "Crear Evento",
    url: routes.crearEvento,
  },
  {
    title: "Cuenta",
    url: routes.cuenta,
  },
];


export const Elementos_SinIniciarSesión = [
  {
    title: "Blog",
    url: routes.blog,
  },

  {
    title: "Iniciar Sesión",
    url: routes.login,
  },
];
