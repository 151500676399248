import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import "./CrearEvento.css";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import { routes } from "../../../../routers/routes";
import { lugares } from "../components/Lugares";
import FormOradores from "../components/FormOradores/FormOradores";
import FormAgenda from "../components/FormAgenda/FormAgenda";

const CrearEvento = () => {
  const { register, handleSubmit, reset, formState } = useForm({});

  const navigate = useNavigate();

  const [value, setValue] = useState();


  //Controladores de datos de oradores en una lista
  const [oradores, setOradores] = useState([]);
  let oradoresArray=[];


  //Controladores de formularios de Oradores
  const [formOradores, setFormoradores] = useState(0);
  const [formOradoresState, setFormoradoresstate] = useState([]);
  let formOradoresArray = [];
 

  const imprimirFormOradores = () => {
    for (let i = 0; i < formOradores; i++) {
      formOradoresArray.push(<FormOradores setOradores={setOradores} oradores={oradores} oradoresArray={oradoresArray}/>);
    }

    setFormoradoresstate(formOradoresArray);
  };

  useEffect(() => {
    imprimirFormOradores();
  }, [formOradores]);



  


  //Controladores de check
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };


  //Controladores para agendas
  
  // const formAgenda = () => {
  //   for (let i = 0; i < dias; i++) {
  //     formarray.push(<FormAgenda />);
  //   }

  //   setArray(formarray);
  // };



  const mostrarAlerta = () => {
    swal({
      title: "Evento creada con exito!",
      icon: "success",
      timer: 3000,
    });
    reset();
    navigate(routes.eventos);
  };

  const crearNuevoEvento = async (formData) => {
    const data = new FormData();
    data.set("titulo", formData.titulo);
    data.set("modalidad", formData.modalidad);
    data.set("lugar", formData.lugar);
    data.set("fecha", formData.fecha);
    data.set("hora", formData.hora);
    data.set("resumen", formData.resumen);
    data.set("descripcion", formData.descripcion);
    data.set("url", formData.url);
    data.set("documento", formData.documento[0]);

    const response = await fetch(
      "https://backend-dedica.onrender.com/evento/createEvento",
      {
        method: "POST",
        body: data,
        credentials: "include",
      }
    );

    if (response.ok) {
      mostrarAlerta();
    }
  };

  return (
    <>
      <div className="FormularioEvento__seccion">
        <Form
          className="FormularioEvento__formulario"
          onSubmit={handleSubmit(crearNuevoEvento)}
        >
          <h5>Crear Evento</h5>
          <Form.Group>
            <Form.Label>Titulo</Form.Label>
            <Form.Control
              type="text"
              placeholder="Titulo"
              {...register("titulo")}
            />
          </Form.Group>
          <Form.Group className="FormularioEvento__modalidadLugarFechaHora">
            <Form.Group>
              <Form.Label>Modalidad</Form.Label>
              <Form.Select {...register("modalidad")}>
                <option></option>
                <option value="virtual">Virtual</option>
                <option value="presencial">Presencial</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Lugar</Form.Label>
              <Form.Select {...register("lugar")}>
                <option></option>
                {lugares.map((lugar) => (
                  <option key={lugar}>{lugar}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Fecha</Form.Label>
              <Form.Control type="date" {...register("fecha")}></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Hora</Form.Label>
              <Form.Control
                type="text"
                placeholder="Hora"
                {...register("hora")}
              ></Form.Control>
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Label>Resumen</Form.Label>
            <Form.Control
              type="summary"
              placeholder="Resumen"
              {...register("resumen")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Imagen</Form.Label>
            <Form.Control type="file" {...register("documento")} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Enlace</Form.Label>
            <Form.Select
              {...register("lugar")}
              value={value}
              onChange={(e) => setValue(e.currentTarget.value)}
            >
              <option value="enlace">Enlace de evento</option>
              <option value="descripcion">Descripción</option>
            </Form.Select>
          </Form.Group>
          {value === "enlace" && (
            <Form.Group>
              <Form.Label>Enlace de evento </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enlace"
                {...register("url")}
              />
            </Form.Group>
          )}
          {value === "descripcion" && (
            <Form.Group>
              <Form.Label>Descripción completa </Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                {...register("descripcion")}
              />
            </Form.Group>
          )}

          <Form.Check
            type="switch"
            id="custom-switch"
            label="Crear pagina de evento"
            checked={isChecked}
            onChange={handleOnChange}
          />
          {isChecked === true && (
            <>
              <Form.Group>
                <Form.Label>Descripción (máximo 250 palabras) </Form.Label>
                <Form.Control as="textarea" rows={6} />
              </Form.Group>

              <Form.Group>
                <Form.Label>Imagen</Form.Label>
                <Form.Control type="file"  />
              </Form.Group>

              <Form.Group>
                <Form.Label>Cantidad de oradores </Form.Label>
                <Form.Control
                  type="number"
                  formOradores={formOradores}
                  onChange={(e) => setFormoradores(e.currentTarget.value)}
                />
              </Form.Group>
              {formOradores > 0 &&
                formOradoresState.map((a) => {
                  return a;
                })}

              {
                <Form.Group>
                  <Form.Label>Cantidad de dias del evento </Form.Label>
                  <Form.Control
                    type="number"
                  />
                </Form.Group>
              }
            </>
          )}

          <Form.Group>
            <Button
              variant="secondary"
              onClick={handleSubmit(crearNuevoEvento)}
            >
              Crear Evento
            </Button>
          </Form.Group>
        </Form>
      </div>
    </>
  );
};

export default CrearEvento;
