import React from "react";
import NavegacionEstrategias from "./NavegacionEstrategias";
import Seccion7 from "../../../components/secciones/seccion7/Seccion7";
import { useTranslation } from "react-i18next";

const Estrategias = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionEstrategias
        titulo={t(`seccionEstrategias.Estrategias`)}
        informacion={t(`seccionEstrategias.Seccion de Estrategias`)}
      />
      <Seccion7
        h2={t(`seccionEstrategias.Estrategias`)}
        p2={t(`seccionEstrategias.texto1`)}
        p3={t(`seccionEstrategias.texto2`)}
        img={require("../../../assets/img/Estrategia.png")}
      />
    </>
  );
};

export default Estrategias;
