import React from "react";
import "./EstilosGlobalesMiembros.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const TarjetaDeMiembro = ({ arrayParam }) => {

  const [t,i18n]=useTranslation("global");

  return (
    <section className="TarjetaDeMiembro">
      {arrayParam.map((item) => (
        <div className="TarjetaMiembro__contenedor">
          <div className="TarjetaDeMiembro__img">
            <img src={require(`../../assets/img/miembros/${item.imagen}`)} alt={item.altImagen} />
          </div>
          <div className="TarjetaDeMiembro__informacion">
            <h3>{item.nombre}</h3>
            <h4>{item.puesto}</h4>
            <p>{t(`seccionMiembros.${item.resumen}`)}</p>

            <div className="TarjetaDeMiembro__redesSociales">
              <a target="_blank" href={item.Twitter}>
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="icon"
                ></FontAwesomeIcon>
              </a>
              <a
                target="_blank"
                href={item.Linkedin}
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="icon"
                ></FontAwesomeIcon>
              </a>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default TarjetaDeMiembro;
