import React, { useState, useEffect } from "react";
import NavegacionEstrategias from "../NavegacionEstrategias";
import TituloPrincipal from "../../../../components/secciones/tituloPrincipal/TituloPrincipal";
import SeccionEvento from '../../../../components/evento/SeccionEvento/SeccionEvento'
import { useTranslation } from "react-i18next";
const Eventos = () => {
  const [eventos, setEventos] = useState([]);

  const [t,i18n]=useTranslation("global");

  const traerEventos = async () => {
    const response = await fetch("https://backend-dedica.onrender.com/evento/getEventos", {
      method: "GET",
    });

    let eventos = await response.json();

    setEventos(eventos);
  };

  useEffect(() => {
    traerEventos();
  }, []);

  
  return (
    <>
      <NavegacionEstrategias
        titulo={t(`seccionEventos.Eventos`)}
        informacion={t(`seccionEventos.Seccion de Eventos`)}
      />
      <TituloPrincipal
        encabezado={t(`seccionEventos.Eventos dentro de Fundación DEDICA`)}
        texto={t(`seccionEventos.texto1`)}
      />


      {eventos.length > 0 &&
        eventos.map((evento) => {
          return(
            <>
              <SeccionEvento key={evento._id} {...evento} />
            </>
          )
        })}
    </>
  );
};

export default Eventos;
