import React from 'react'
import NavegacionAliados from './NavegacionAliados'
import { useTranslation } from 'react-i18next';

const FundacionesOrganizacionesNoGubernamentales = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <NavegacionAliados titulo={t('seccionFundacionesOrganizacionesNoGubernamentales.Fundaciones y Organizaciones No Gubernamentales')} />
    </>
  )
}

export default FundacionesOrganizacionesNoGubernamentales