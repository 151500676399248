export const informacionMiembros=[
    {
        id:1,
        nombre:"Dr. José Ramón Morales Ávalos",
        puesto: "CEO",
        resumen: "Líder de Innovación de Intel GDC por más de 20 años, CEO de Corporativo Ilimita Digital y Miembro de CANIETI Región Occidente. Cuenta con más de 20 años de carrera en entornos corporativos y de alta tecnología; persona de sólido liderazgo y trayectoria empresarial creando nuevos roles, tecnologías y mercados de valor agregado",
        imagen: 'Ramón Morales.jpg',
        altImagen: "fotografía del miembro",
        Twitter:'',
        Linkedin:''
    },
    {
        id:2,
        nombre:"Ing. Manuel Haro Márquez",
        puesto: "Chairman",
        resumen: "Mexicano apasionado de la tecnología y del software libre, académico comprometido con la Innovación Colaborativa y el impulso al talento en México. Analógico de nacimiento y nomada digital aprendiz de extraordinarios líderes",
        imagen: "Manuel.jpeg",
        altImagen: "fotografía del miembro",
        Twitter:'https://twitter.com/caxcan70?lang=es',
        Linkedin:'https://www.linkedin.com/in/manuel-haro-89631a56'
    },
    {
        id:3,
        nombre:"Dr. Oscar Pérez Veyna",
        puesto: "Chief Data officer",
        imagen: "oscar.jpeg",
        altImagen: "fotografía del miembro",
        Twitter:'',
        Linkedin:''
    },
]
