import React from "react";

import logo from "../../assets/img/dedicalogo.png";

import "./PieDePagina.css";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { routes } from "../../routers/routes";
import { useTranslation } from "react-i18next";

const PieDePagina = () => {

  const [t,i18n]=useTranslation("global");

  return (
    <>
      <footer className="PieDePagina">
        <div className="PieDePagina__primeraSeccion">
          <div className="PieDePagina__primeraSeccion__logoIdiomas__paginas">
            <div className="PieDePagina__primeraSeccion__logoIdiomas">
              <img src={logo} alt={"Logo"} />
              
            </div>
            <div className="PieDePagina__primeraSeccion__paginas">
              <nav>
                <h6>{t('pieDePagina.Secciones')}</h6>
                <ul>
                  <li>
                    <Link to={routes.nosotros}>{t('navbar.Nosotros')}</Link>
                  </li>
                  <li>
                    <Link to={routes.estrategias}>{t('navbar.Estrategias')}</Link>
                  </li>
                  <li>
                    <Link to={routes.aliados}>{t('navbar.Aliados')}</Link>
                  </li>
                  <li>
                    <Link to={routes.innovacion}>{t('navbar.Innovacion')}</Link>
                  </li>
                  <li>
                    <Link to={routes.beneficiarios}>{t('navbar.Beneficiarios')}</Link>
                  </li>
                  <li>
                    <Link to={routes.contacto}>{t('navbar.Contacto')}</Link>
                  </li>
                </ul>
              </nav>

              <nav>
                <h6>{t('pieDePagina.Informativo')}</h6>
                <ul>
                <Link to={routes.blog}>{t('mininavbar.Blog')}</Link>
                </ul>
              </nav>
              
            </div>
          </div>
          <div className="PieDePagina__primeraSeccion__contacto">
            <h6>{t('pieDePagina.Contactar')}</h6>
            <div>
              <FontAwesomeIcon icon={faEnvelope} className="correo" />
              <p>info@dedica.org.mx</p>
            </div>
            <div className="redesSociales">
              <a href="./">
                <FontAwesomeIcon
                  icon={faFacebook}
                  className="icon"
                ></FontAwesomeIcon>
              </a>
              <a href="./">
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="icon"
                ></FontAwesomeIcon>
              </a>
              <a target="_blank" href="https://twitter.com/osilmx?lang=es">
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="icon"
                ></FontAwesomeIcon>
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/fundaci%C3%B3n-dedica/?viewAsMember=true">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="icon"
                ></FontAwesomeIcon>
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className="PieDePagina__segundaSeccion">
          <div className="PieDePagina__segundaSeccion__seccionDerechos">
            <p>2023 | All Right reserved</p>
          </div>
          <div className="PieDePagina__segundaSeccion__secciones">
            <a href="./">FAQ</a>
            <a href="./">{t('pieDePagina.Privacidad')}</a>
            <a href="./">{t('pieDePagina.Terminos & Condiciones')}</a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default PieDePagina;
